<template>
  <div class="max-container">
    <div class="alram-box">
      <!-- 标签页 -->
      <div class="tarbar-box">
        <div class="tabs">
          <div
            class="tab-one"
            v-for="tab in tabList"
            :class="activeTab == tab.value ? 'active-tab' : ''"
            @click="changeTab(tab.value)"
          >
            <span></span>
            <div>{{ tab.label }}</div>
          </div>
          <div
            class="tab-border"
            @click="drawerVisible"
            :class="visible ? 'openclose' : ''"
          >
            {{ visible ? "收起筛选" : "展开筛选" }} <a-icon type="caret-down" />
          </div>
        </div>
        <div class="right-box">
          <a-icon type="vertical-align-bottom" title="导出" class="icon-left" />
          <a-icon
            type="sync"
            class="icon-left"
            @click="getTableList"
            title="刷新"
          />
          <!-- 下拉菜单 -->
          <myDrop
            placement="bottomLeft"
            :defaultFlag="defaultFlag"
            :dropdownList="dropdownList"
            @chooseItem="chooseItem"
          ></myDrop>
        </div>
      </div>
      <!-- 表格内容 -->
      <div
        style="position: relative; z-index: 2; overflow: hidden"
        class="table-box"
      >
        <!-- 表格 -->
        <myTable
          :columns="columns"
          :dataSource="tableData"
          :total="total"
          @changePagination="getTableList"
          :scroll="{ x: 200, y: 450 }"
        >
          <template slot="alarmTit" slot-scope="slotData">
            <div @click="toDetail(slotData.scope)" class="name-box">
              {{ slotData.scope.alarmTitle }}
            </div>
          </template>
          <template #statusSlot="slotData">
            {{ slotData.scope.status == 1 ? "发生中" : "已解决" }}
          </template>
          <!-- <template #level="slotData">
            <span>{{ getLavelName(slotData.scope.alarmLevel) }}</span>
          </template> -->
          <!-- <template slot="operation" slot-scope="slotData">
            <div class="operation-box">
              <a-icon type="file-add" title="提交工单" />
              <a-icon type="solution" class="icon-left" title="添加维保记录" />
            </div>
          </template> -->
        </myTable>
        <!-- 筛选的抽屉 -->
        <a-drawer
          placement="top"
          :closable="false"
          :visible="visible"
          :get-container="false"
          :wrap-style="{ position: 'absolute' }"
          @close="onClose"
          class="screen-drawer"
          :bodyStyle="{
            background: 'fff',
            height: '100%',
            padding: '0',
          }"
        >
          <div class="screen-box">
            <div class="screen-content">
              <div class="alarm-row">
                <div class="row-title">等级：</div>
                <div class="row-main">
                  <div
                    class="lavel"
                    v-for="l in plainOptions"
                    @click="chooseLavel(l.value)"
                    :class="lavelChoose.includes(l.value) ? 'choose-lavel' : ''"
                  >
                    {{ l.label }}
                  </div>
                </div>
              </div>
              <div class="alarm-row">
                <div class="row-title">报警开始时间</div>
                <div class="row-main">
                  <a-week-picker v-model="alarmTime" />
                </div>
              </div>
            </div>
            <div class="btn-box">
              <a-button @click="onClose">取消</a-button>
              <a-button @click="resetSearch">重置</a-button>
              <a-button type="primary" @click="searchConfirm">确定</a-button>
            </div>
          </div>
        </a-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import { queryAlarmList } from "@/api/alarm.js";
import moment from "moment";
export default {
  data() {
    return {
      activeTab: 1,
      tabList: [
        {
          value: "2",
          label: "全部",
        },
        {
          value: "1",
          label: "发生中",
        },
        {
          value: "0",
          label: "已恢复",
        },
      ],
      columns: [
        {
          title: "报警名称",
          dataIndex: "alarmTitle",
          scopedSlots: {
            customRender: "alarmTit",
          },
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: {
            customRender: "statusSlot",
          },
        },
        // {
        //   title: "等级",
        //   dataIndex: "alarmLevel",
        //   scopedSlots: {
        //     customRender: "level",
        //   },
        // },
        {
          title: "电站",
          dataIndex: "stationName",
        },
        {
          title: "设备",
          dataIndex: "deviceSn",
        },
        {
          title: "报警开始时间",
          dataIndex: "createTime",
        },
        {
          title: "恢复时间",
        },
        // {
        //   title: "操作",
        //   scopedSlots: {
        //     customRender: "operation",
        //   },
        // },
      ],
      tableData: [],
      total: 0,
      // 筛选抽屉
      visible: false,
      moment,
      defaultFlag: "0",
      plainOptions: [
        {
          value: "1",
          label: "提示",
        },
        {
          value: "2",
          label: "警告",
        },
        {
          value: "3",
          label: "故障",
        },
      ],
      dropdownList: [
        {
          label: "关闭",
          value: "0",
        },
        {
          label: "3分钟",
          value: "3",
        },
        {
          label: "6分钟",
          value: "6",
        },
        {
          label: "9分钟",
          value: "9",
        },
      ],
      // 筛选
      lavelChoose: [],
      alarmTime: null,
    };
  },
  props: {
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    changeTab(value) {
      this.activeTab = value;

      this.getTableList();
    },
    onClose() {
      this.visible = false;
    },
    // 获取表格数据
    getTableList(pageMsg) {
      let alarm = {
        status: this.activeTab == 2 ? null : this.activeTab,
        alarmLevel: this.lavelChoose,
        startTime: this.alarmTime
          ? moment(this.alarmTime).day(1).format("YYYY-MM-DD")
          : null,
        endTime: this.alarmTime
          ? moment(this.alarmTime).day(7).format("YYYY-MM-DD")
          : null,
      };

      let requestPage = {
        page: 1,
        limit: 50,
        deviceSn: this.currentData.deviceSn,
      };
      if (pageMsg?.page) {
        requestPage.page = pageMsg.page;
        requestPage.limit = pageMsg.pageSize;
      }
      queryAlarmList({ ...requestPage, alarm }).then((res) => {
        this.tableData = res.data;
        this.total = res.count;
      });
    },

    drawerVisible() {
      this.visible = !this.visible;
    },
    chooseItem(item) {
      this.defaultFlag = item;
    },

    // 查询警告详情
    getLavelName(l) {
      let lavel = this.plainOptions.find((item) => {
        return item.value == l;
      });
      if (lavel) {
        return lavel.label;
      } else {
        return "--";
      }
    },
    // 等级选择
    chooseLavel(value) {
      let findIndex = this.lavelChoose.findIndex((item) => item == value);
      if (findIndex == -1) {
        this.lavelChoose.push(value);
      } else {
        this.lavelChoose.splice(findIndex, 1);
      }
    },
    searchConfirm() {
      this.getTableList();
      this.visible = false;
    },
    resetSearch() {
      this.alarmTime = null;
      this.lavelChoose = [];
      this.getTableList();
    },
  },
  mounted() {
    this.getTableList();
  },
};
</script>
<style lang="less" scoped>
.max-container {
  width: 100%;
  .alram-box {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    // tabs
    .tarbar-box {
      padding-bottom: 12px;
      position: relative;
      padding-right: 16px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tabs {
        display: flex;
        padding-left: 16px;
        align-items: center;
        box-sizing: border-box;
        .tab-one {
          padding: 10px 0 8px 0;
          margin-right: 24px;
          font-size: 12px;
          cursor: pointer;
          color: #515561;
          box-sizing: border-box;
        }
        .tab-border {
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          font-size: 12px;
          cursor: pointer;
          color: #515561;
          box-sizing: border-box;
          padding-left: 16px;
        }
        .openclose {
          color: #048fff;
          i {
            transform: rotate(180deg);
          }
        }
        .open-choose {
          margin-top: 3px;
          cursor: pointer;
          padding-left: 16px;
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          &:hover {
            color: #048fff;
          }
          span {
            margin-right: 1px;
          }
          transition: all 1s;
        }
        .active-choose {
          color: #048fff;
          .icon-down {
            transform: rotate(180deg);
          }
        }
        .active-tab {
          color: #048fff;
          border-bottom: 2px solid #048fff;
        }
      }
      .right-box {
        font-size: 16px;
        display: flex;
        align-items: center;
        i {
          cursor: pointer;
        }
        .icon-left {
          margin-right: 8px;
          transition: all 0.4s;
          &:hover {
            transform: rotate(90deg);
          }
        }
      }
    }
    .table-box {
      min-height: 220px;
      .name-box {
        cursor: pointer;
      }
    }

    // 操作样式
    .operation-box {
      width: 100%;
      padding-left: 15px;
      font-size: 16px;
      i {
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          color: #048fff;
        }
      }
      .icon-left {
        margin-left: 30px;
      }
    }
  }
}
/deep/.screen-drawer {
  .ant-drawer-content-wrapper {
    height: 200px !important;
  }
  .screen-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .screen-content {
      padding-top: 12px;
      flex-grow: 0;
      .alarm-row {
        display: flex;
        color: #515561;
        border-bottom: 1px solid #d9d9d9;
        font-size: 12px;
        .row-title {
          padding: 16px;
          padding-right: 4px;
          background: #f0f0f1;
          width: 112px;
        }
        .row-main {
          padding: 16px;
          display: flex;
          .lavel {
            margin-right: 24px;
            cursor: pointer;
            &:hover {
              color: #048fff;
            }
          }
          .choose-lavel {
            color: #048fff;
          }
        }
      }
    }
    .btn-box {
      flex-grow: 1;
      padding-left: 128px;
      display: flex;
      align-items: center;
      .ant-btn {
        font-size: 12px;
        height: 32px;
        margin-right: 16px;
      }
    }
  }
}
</style>