<template>
  <div class="max-container">
    <!-- 基础信息 -->
    <div class="row-box">
      <div class="content-box">
        <div class="title-box">
          <span>基础信息</span>
          <div class="up-down" @click="changeFlag(0)">
            {{ getInclude(0) ? "展开" : "收起"
            }}<a-icon type="up" :class="getInclude(0) ? 'roteIcon' : ''" />
          </div>
        </div>
        <div :class="getInclude(0) ? 'pack-up' : 'updown-box'">
          <div class="content-msg">
            <div class="grid-box grid-base">
              <div class="one-msg" v-for="item in baseInfo">
                <span>{{ item.title }}</span>
                <span class="one-detail">{{ item.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CT1 -->
    <!-- <div class="row-box">
      <div class="content-box">
        <div class="title-box">
          <span>CT</span>
          <div class="up-down" @click="changeFlag(1)">
            {{ getInclude(1) ? "展开" : "收起"
            }}<a-icon type="up" :class="getInclude(1) ? 'roteIcon' : ''" />
          </div>
        </div>
        <div :class="getInclude(1) ? 'pack-up' : 'updown-box'">
          <div class="content-msg">
            <div class="grid-box grid-real">
              <div class="one-msg" v-for="item in CT">
                <span>{{ item.title }}</span>
                <span class="one-detail"
                  >{{ item.value }}{{ item.unit ? item.unit : "" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- CT2 -->
    <!-- <div class="row-box">
      <div class="content-box">
        <div class="title-box">
          <span>CT2</span>
          <div class="up-down" @click="changeFlag(2)">
            {{ getInclude(2) ? "展开" : "收起"
            }}<a-icon type="up" :class="getInclude(2) ? 'roteIcon' : ''" />
          </div>
        </div>
        <div :class="getInclude(2) ? 'pack-up' : 'updown-box'">
          <div class="content-msg">
            <div class="grid-box grid-real">
              <div class="one-msg" v-for="item in CT2">
                <span>{{ item.title }}</span>
                <span class="one-detail"
                  >{{ item.value }}{{ item.unit ? item.unit : "" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- CT3 -->
    <!-- <div class="row-box">
      <div class="content-box">
        <div class="title-box">
          <span>CT3</span>
          <div class="up-down" @click="changeFlag(3)">
            {{ getInclude(3) ? "展开" : "收起"
            }}<a-icon type="up" :class="getInclude(3) ? 'roteIcon' : ''" />
          </div>
        </div>
        <div :class="getInclude(3) ? 'pack-up' : 'updown-box'">
          <div class="content-msg">
            <div class="grid-box grid-real">
              <div class="one-msg" v-for="item in CT3">
                <span>{{ item.title }}</span>
                <span class="one-detail"
                  >{{ item.value }}{{ item.unit ? item.unit : "" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- 总计 -->
    <div class="row-box">
      <div class="content-box">
        <div class="title-box">
          <span>总计</span>
          <div class="up-down" @click="changeFlag(4)">
            {{ getInclude(4) ? "展开" : "收起"
            }}<a-icon type="up" :class="getInclude(4) ? 'roteIcon' : ''" />
          </div>
        </div>
        <div :class="getInclude(4) ? 'pack-up' : 'updown-box'">
          <div class="content-msg">
            <div class="grid-box grid-real">
              <div class="one-msg" v-for="item in totalCount">
                <span>{{ item.title }}：</span>
                <span class="one-detail"
                  >{{ item.value }}{{ item.unit ? item.unit : "" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row-box">
      <div class="content-box">
        <div class="title-box">
          <span>历史数据</span>
          <div class="up-down" @click="changeFlag(5)">
            {{ getInclude(5) ? "展开" : "收起"
            }}<a-icon type="up" :class="getInclude(5) ? 'roteIcon' : ''" />
          </div>
        </div>
        <div :class="getInclude(5) ? 'pack-up' : 'updown-box'">
          <div class="power-msg flex-end">
            <div class="right-content">
              <div class="time-more">
                <div
                  v-for="t in timeTypeList"
                  :class="t.value == activeTime ? 'active-time' : ''"
                  @click="changeTimeType(t.value)"
                >
                  {{ t.label }}
                </div>
              </div>
              <!-- <div class="btn-operation ml-16">参数选择</div> -->
              <div class="btn-operation ml-16">导出报表</div>

              <div class="time-picker">
                <!-- <a-icon type="left" @click="dealTime(-1)" /> -->
                <a-date-picker
                  class="picker"
                  :allowClear="false"
                  v-model="historyTime"
                  v-if="activeTime == 1"
                />
                <a-week-picker
                  :allowClear="false"
                  v-model="historyTime"
                  v-if="activeTime == 2"
                />
                <a-month-picker
                  :allowClear="false"
                  v-model="historyTime"
                  v-if="activeTime == 3"
                />
                <a-date-picker
                  class="picker"
                  :allowClear="false"
                  v-model="historyTime"
                  format="YYYY"
                  mode="year"
                  :open="orderOpen"
                  @openChange="openOrder"
                  @panelChange="panelOrder"
                  v-if="activeTime == 4"
                />
                <!-- <a-range-picker
                  v-if="activeTime == 5"
                  class="rang-picker"
                  v-model="allTime"
                  format="YYYY"
                  disabled
                /> -->
                <!-- <a-icon type="right" @click="dealTime(1)" /> -->
              </div>
            </div>
          </div>
          <div id="historyE"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import moment from "moment";
import {
  alarmDetailEcharts,
  monitoringBarEcharts,
} from "../config/alarmEchart";
import { ammerterDetail } from "@/api/device.js";
export default {
  data() {
    return {
      // 基础信息
      baseInfo: [
        {
          title: "SN号：",
          value: "METER001",
          prop: "deviceSn",
        },
        // {
        //   title: "电表倍率：",
        //   value: "1",
        //   prop: "deviceSn",
        // },
      ],

      // CT1
      CT: [
        {
          title: "A相电压：",
          value: "0",
          unit: "v",
          prop: "ua",
        },
        {
          title: "B相电压：",
          value: "0",
          unit: "v",
          prop: "ub",
        },
        {
          title: "C相电压：",
          value: "0",
          unit: "V",
          prop: "uc",
        },
        {
          title: "A相电流：",
          value: "0",
          unit: "A",
          prop: "ia",
        },
        {
          title: "B相电流：",
          value: "0",
          unit: "A",
          prop: "ib",
        },
        {
          title: "C相电流：",
          value: "0",
          unit: "A",
          prop: "ic",
        },
      ],
      CT2: [
        {
          title: "有功功率CT2：",
          value: "5",
          unit: "kW",
          prop: "",
        },
        {
          title: "无功功率CT2：",
          value: "5",
          unit: "Var",
          prop: "",
        },
        {
          title: "视在功率CT2：",
          value: "1",
          unit: "VA",
          prop: "",
        },

        {
          title: "功率因数CT2：",
          value: "vv.:YMzg+u5kOjJ!6Dco",
          unit: "",
          prop: "",
        },
        {
          title: "电压CT2：",
          value: "10523",
          unit: "V",
          prop: "",
        },
        {
          title: "电流CT2：",
          value: "5",
          unit: "A",
          prop: "",
        },
      ],

      CT3: [
        {
          title: "有功功率CT3：",
          value: "5",
          unit: "kW",
          prop: "",
        },
        {
          title: "无功功率CT3：",
          value: "5",
          unit: "Var",
          prop: "",
        },
        {
          title: "视在功率CT3：",
          value: "1",
          unit: "VA",
          prop: "",
        },

        {
          title: "功率因数CT3：",
          value: "vv.:YMzg+u5kOjJ!6Dco",
          unit: "",
          prop: "",
        },
        {
          title: "电压CT3：",
          value: "10523",
          unit: "V",
          prop: "",
        },
        {
          title: "电流CT3：",
          value: "5",
          unit: "A",
          prop: "",
        },
      ],

      totalCount: [
        {
          value: "",
          prop: "groupElel",
          title: "组合有功总电能",
          unit: "kwh",
        },
        { value: "", prop: "fpFKwh", title: "正向有功总电能", unit: "kwh" },
        {
          value: "",
          prop: "rfpFKwh",
          title: "反相有功总电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "totalEleCombReac1",
          title: "组合无功1总电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "totalEleCombReac2",
          title: "组合无功2总电能",
          unit: "kwh",
        },
        // { value: "", prop: "unionElec", title: "关联电能", unit: "kwh" },
        // {
        //   value: "",
        //   prop: "groupElel1",
        //   title: "上1结算日组合有功总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel2",
        //   title: "上1结算日正向有功总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel3",
        //   title: "上1结算日反相有功总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel4",
        //   title: "上1结算日组合无功1总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel5",
        //   title: "上1结算日组合无功2总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel6",
        //   title: "上1结算日关联电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel7",
        //   title: "上12结算日组合有功总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel8",
        //   title: "上12结算日正向有功总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel9",
        //   title: "上12结算日反相有功总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel10",
        //   title: "上12结算日组合无功1总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel11",
        //   title: "上12结算日组合无功2总电能",
        //   unit: "kwh",
        // },
        // {
        //   value: "",
        //   prop: "groupElel12",
        //   title: "上12结算日关联电能",
        //   unit: "kwh",
        // },
        // { value: "", prop: "power", title: "有功功率", unit: "kW" },
        // { value: "", prop: "qPower", title: "无功功率", unit: "kVAR" },
        // {
        //   value: "",
        //   prop: "totalPowerFactor",
        //   title: "总功率因数",
        //   unit: "",
        // },
        // {
        //   value: "",
        //   prop: "zeroCurrent",
        //   title: "零线电流",
        //   unit: "A",
        // },
        // { value: "", prop: "rate", title: "电网频率", unit: "Hz" },
        // { value: "", prop: "temp", title: "表内温度", unit: "°C" },
        {
          value: "",
          prop: "comActiveElec1",
          title: "组合有功费率1电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "comActiveElec2",
          title: "组合有功费率2电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "comActiveElec3",
          title: "组合有功费率3电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "comActiveElec4",
          title: "组合有功费率4电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "activeTipElec",
          title: "正向有功尖电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "activePeakElec",
          title: "正向有功峰电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "activeFlatElec",
          title: "正向有功平电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "activeValleyElec",
          title: "正向有功谷电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "rActiveTipElec",
          title: "反向有功尖电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "rActivePeakElec",
          title: "反向有功峰电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "rActiveFlatElec",
          title: "反向有功平电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "rActiveValleyElec",
          title: "反向有功谷电能",
          unit: "kwh",
        },
        {
          value: "",
          prop: "groupReactive1Elec1",
          title: "组合无功1费率1电能",
          unit: "kVARh",
        },
        {
          value: "",
          prop: "groupReactive1Elec2",
          title: "组合无功1费率2电能",
          unit: "kVARh",
        },
        {
          value: "",
          prop: "groupReactive1Elec3",
          title: "组合无功1费率3电能",
          unit: "kVARh",
        },
        {
          value: "",
          prop: "groupReactive1Elec4",
          title: "组合无功1费率4电能",
          unit: "kVARh",
        },
        {
          value: "",
          prop: "groupReactive2Elec1",
          title: "组合无功2费率1电能",
          unit: "kVARh",
        },
        {
          value: "",
          prop: "groupReactive2Elec2",
          title: "组合无功2费率2电能",
          unit: "kVARh",
        },
        {
          value: "",
          prop: "groupReactive2Elec3",
          title: "组合无功2费率3电能",
          unit: "kVARh",
        },
        {
          value: "",
          prop: "groupReactive2Elec4",
          title: "组合无功2费率4电能",
          unit: "kVARh",
        },
        {
          value: "",
          prop: "totalMaximum",
          title: "正向有功总最大需量",
          unit: "kW",
        },
        {
          value: "",
          prop: "rTotalMaximum",
          title: "反向有功总最大需量",
          unit: "kW",
        },
      ],
      // 展开收起
      packUpFlag: [],

      //历史数据查询时间
      historyTime: moment(),
      timeTypeList: [
        {
          value: "1",
          label: "日",
        },
        {
          value: "2",
          label: "周",
        },
        {
          value: "3",
          label: "月",
        },
        {
          value: "4",
          label: "年",
        },
        // {
        //   value: "5",
        //   label: "总",
        // },
      ],
      activeTime: "1",
      orderOpen: false,
      allTime: [moment("2015"), moment("2023")],
      chartInstance: null,
    };
  },
  props: {
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    historyTime(newv) {
      this.getDetail();
    },
  },
  methods: {
    getInclude(index) {
      return this.packUpFlag.includes(index);
    },
    changeFlag(index) {
      let findIndex = this.packUpFlag.findIndex((item) => {
        return item == index;
      });
      if (findIndex == -1) {
        this.packUpFlag.push(index);
      } else {
        this.packUpFlag.splice(findIndex, 1);
      }
    },
    dealTime(flag) {},
    changeTimeType(value) {
      this.activeTime = value;
      this.getDetail();
    },
    openOrder(val) {
      if (val) {
        this.orderOpen = true;
      } else {
        this.orderOpen = false;
      }
    },
    panelOrder(val) {
      this.historyTime = moment(val);
      this.orderOpen = false;
    },
    getTime() {
      let startTime, endTime;
      console.log(this.historyTime, this.timeType);
      if (this.activeTime == 1) {
        startTime = moment(this.historyTime).format("YYYY-MM-DD 00:00:00");
        endTime = moment(this.historyTime).format("YYYY-MM-DD 00:00:00");
      } else if (this.activeTime == 2) {
        startTime = moment(this.historyTime)
          .startOf("week")
          .format("YYYY-MM-DD 00:00:00");
        endTime = moment(this.historyTime)
          .endOf("week")
          .format("YYYY-MM-DD 00:00:00");
        console.log("week");
      } else if (this.activeTime == 3) {
        startTime = moment(this.historyTime)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        endTime = moment(this.historyTime)
          .endOf("month")
          .format("YYYY-MM-DD 00:00:00");
      } else {
        startTime = moment(this.historyTime)
          .startOf("year")
          .format("YYYY-MM-DD 00:00:00");
        endTime = moment(this.historyTime)
          .endOf("year")
          .format("YYYY-MM-DD 00:00:00");
      }
      return {
        startTime,
        endTime,
      };
    },
    getDetail() {
      ammerterDetail({
        id: this.currentData.id,
        timeType: this.activeTime,
        ...this.getTime(),
      }).then((res) => {
        console.log(res, "电表详情");
        this.CT.forEach((item) => {
          item.value = res.data.meter[item.prop];
        });
        this.totalCount.forEach((item) => {
          item.value = res.data.meter[item.prop]
            ? res.data.meter[item.prop]
            : "--";
        });
        delete res.data.meter;
        if (this.activeTime == 1 || this.activeTime == 2) {
          this.initDayWeekEcharts(res.data);
        } else {
          this.initMonthYear(res.data);
        }
      });
    },
    instance() {
      const comparebox = document.getElementById("historyE");
      if (!this.chartInstance) {
        this.chartInstance = this.echarts.init(comparebox, null, {
          devicePixelRatio: window.devicePixelRatio,
        });
      }
    },
    initEcharts() {
      const comparebox = document.getElementById("historyE", null, {
        devicePixelRatio: window.devicePixelRatio,
      });
      const compareEchart = this.echarts.init(comparebox);
      compareEchart.setOption(alarmDetailEcharts());
      setTimeout(() => {
        compareEchart.resize();
      }, 0);
    },
    initDayWeekEcharts(echartsData) {
      this.instance();
      this.chartInstance.clear();
      let minTime, maxTime;
      let legend = ["组合有功总电能", "正向有功总电能", "反相有功总电能"];
      let yData = [
        {
          name: "组合有功总电能",
          data: [],
          type: "line",
          prop: "groupElel",
          symbol: "none",
        },
        {
          name: "正向有功总电能",
          data: [],
          type: "line",
          prop: "fpFKwh",
          symbol: "none",
        },
        {
          name: "反相有功总电能",
          data: [],
          type: "line",
          symbol: "none",
          prop: "rfpFKwh",
        },
      ];
      if (Object.keys(echartsData).length > 0) {
        // echartsData
        for (let k in echartsData) {
          yData.forEach((item) => {
            if (item.prop == "fpFKwh") {
              item.data.push([
                echartsData[k][0].createTime,
                echartsData[k][0].fpFKwh,
              ]);
            } else if (item.prop == "groupElel") {
              item.data.push([
                echartsData[k][0].createTime,
                echartsData[k][0].groupElel,
              ]);
            } else {
              item.data.push([
                echartsData[k][0].createTime,
                echartsData[k][0].rfpFKwh,
              ]);
            }
          });
        }
        yData.forEach((item) => {
          item.data.sort((a, b) => {
            return moment(a[0]).valueOf() - moment(b[0]).valueOf();
          });
        });
        minTime = moment(yData[0].data[0][0]).valueOf();
        let index = yData[0].data.length - 1;
        maxTime = moment(yData[0].data[index][0]).valueOf();
        this.chartInstance.setOption(
          alarmDetailEcharts({ min: minTime, max: maxTime }, legend, yData)
        );
      }
      console.log(echartsData, "------");
    },
    initMonthYear(echartsData) {
      let xData = [],
        yData = [];
      let arrData = [];

      this.instance();
      this.chartInstance.clear();
      if (Object.keys(echartsData).length > 0) {
        for (let k in echartsData) {
          arrData.push({ time: k, value: echartsData[k] });
        }
        arrData.sort((a, b) => {
          return moment(a.time).valueOf() - moment(b.time).valueOf();
        });
        arrData.map((item) => {
          xData.push(moment(item.time).format("YYYY-MM-DD"));
          yData.push(item.value);
        });

        this.chartInstance.setOption(monitoringBarEcharts({ xData, yData }));
      }
    },
  },
  mounted() {
    // this.initEcharts();
    this.getDetail();
  },
};
</script>
  <style lang="less" scoped>
.max-container {
  .row-box {
    position: relative;
    border: 1px solid rgba(37, 43, 58, 0.1);
    border-radius: 4px;
    background: #fff;
    padding: 16px 0;
    box-sizing: border-box;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
    margin-bottom: 8px;
    .content-box {
      padding: 0 16px;

      // 标题
      .title-box {
        display: flex;
        justify-content: space-between;
        span {
          font-size: 16px;
          color: #252b3a;
        }
        .up-down {
          font-size: 12px;
          color: #048fff;
          cursor: pointer;
          display: flex;
          align-items: center;
          i {
            font-size: 16px;
          }
          .roteIcon {
            transform: rotate(180deg);
          }
        }
      }
      .content-msg {
        .grid-box {
          display: grid;

          margin-top: 16px;
          .one-msg {
            font-size: 12px;
            color: #00000073;
            .one-detail {
              color: #515561;
            }
          }
        }
        .grid-base {
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap: 16px;
        }
        .grid-real {
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap: 16px;
        }
        .grid-version {
          grid-template-columns: 1fr 1fr 1fr;
        }
        .table-box {
          width: 100%;
          display: grid;
          grid-row-gap: 16px;
          height: max-content;
          .table-title,
          .table-content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            font-size: 12px;
            color: #048fff;
            div {
              text-align: center;
            }
          }
          .table-title {
            div {
              color: #95929c;
            }
          }
        }
        .center-box {
          display: flex;
          justify-content: center;
          .progress-box {
            width: 90%;
            height: 10px;
            position: relative;
            background: #cde9ff;
            border-radius: 4px;
            @keyframes widthAdd {
              from {
                width: 0;
              }
              to {
                width: 100%;
              }
            }
            &::before {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 0;
              animation: widthAdd 4s linear infinite;
              border-radius: 4px;
              height: 100%;
              background: #048fff;
            }
            .square-box {
              width: 56px;
              height: 56px;
              padding: 5px;
              box-sizing: border-box;
              position: absolute;
              left: 50%;
              top: 0;
              background: #fff;
              transform: translateY(-40%);
              .square-inner {
                width: 100%;
                height: 100%;
                background: #048fff;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                .square-ctn {
                  display: flex;
                  flex-direction: column;
                  font-size: 12px;
                  align-items: center;
                  .small {
                    transform: scale(0.8);
                    font-weight: 700;
                  }
                  .line {
                    width: 70%;
                    height: 1px;
                    background: #fff;
                  }
                  .bolang {
                    font-size: 20px;
                    line-height: 10px;
                  }
                }
              }
            }
          }
        }
      }

      .updown-box {
        overflow: hidden;
        max-height: 3000px;
      }
      .pack-up {
        overflow: hidden;
        height: 0;
      }

      .power-msg {
        box-sizing: border-box;
        padding: 0 20px;
        margin-top: 20px;
        .grid-box {
          display: grid;
          margin-top: 16px;
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap: 16px;
          .one-msg {
            font-size: 12px;
            color: #00000073;
            .one-detail {
              color: #515561;
            }
          }
        }
        .right-content {
          display: flex;
          align-items: center;
          .time-more {
            height: 24px;
            display: flex;
            div {
              border: 1px solid #d9d9d9;
              padding: 0 8px;
              font-size: 12px;
              height: 100%;
              line-height: 22px;
              cursor: pointer;
              &:hover {
                color: #048fff;
                background-color: #048fff1a;
                border-color: #048fff;
              }
            }
            .active-time {
              color: #048fff;
              background-color: #048fff1a;
              border-color: #048fff;
            }
          }
          .time-picker {
            margin-left: 8px;
            cursor: pointer;
            &:hover {
              color: #048fff;
            }
            .picker {
              margin: 0 6px;
            }
            .ant-calendar-picker {
              /deep/.ant-calendar-picker-input {
                width: 128px;
                height: 30px;
                font-size: 12px;
              }
              // height: 24px;
            }
            .rang-picker {
              /deep/.ant-calendar-picker-input {
                width: 148px;
              }
            }
          }
        }
      }
      .flex-end {
        display: flex;
        justify-content: flex-end;
      }
      .btn-operation {
        padding: 0 14px;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        font-size: 12px;
        height: 22px;
        line-height: 20px;
        color: #00000073;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          border-color: #048fff;
          color: #048fff;
        }
      }
      #historyE {
        height: 384px;
        width: 100%;
      }
    }
  }
}
</style>