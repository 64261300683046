<template>
    <div class="max-container">
        <!-- 基础信息 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>基础信息</span>
                    <div class="up-down" @click="changeFlag(0)">
                        {{ getInclude(0) ? "展开" : "收起" }}
                        <a-icon type="up" :class="getInclude(0) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(0) ? 'pack-up' : 'updown-box'">
                    <div class="content-msg">
                        <div class="grid-box grid-base">
                            <div class="one-msg" v-for="item in baseInfo">
                                <span>{{ item.title }}</span>
                                <span class="one-detail">{{ item.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 版本信息 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>版本信息</span>
                    <div class="up-down" @click="changeFlag(1)">
                        {{ getInclude(1) ? "展开" : "收起"
                        }}
                        <a-icon type="up" :class="getInclude(1) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(1) ? 'pack-up' : 'updown-box'">
                    <div class="content-msg">
                        <div class="grid-box grid-version">
                            <div class="one-msg" v-for="item in version">
                                <span>{{ item.title }}</span>
                                <span class="one-detail">{{ item.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 发电 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>发电</span>
                    <div class="up-down" @click="changeFlag(2)">
                        {{ getInclude(2) ? "展开" : "收起"
                        }}
                        <a-icon type="up" :class="getInclude(2) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(2) ? 'pack-up' : 'updown-box'">
                    <div class="content-msg flex-box mt-16">
                        <div class="table-box">
                            <div class="table-title">
                                <div>直流</div>
                                <div>电压</div>
                                <div>电流</div>
                                <div>功率</div>
                            </div>
                            <div class="table-content" v-for="direct in directList">
                                <div>{{ direct.title }}</div>
                                <div>{{ direct.voltage }}V</div>
                                <div>{{ direct.current }}A</div>
                                <div>{{ direct.power }}W</div>
                            </div>
                        </div>
                        <div class="center-box">
                            <div class="progress-box">
                                <div class="square-box">
                                    <div class="square-inner">
                                        <div class="square-ctn">
                                            <span class="small">DC/AC</span>
                                            <span class="line"></span>
                                            <span class="bolang">～</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-box">
                            <div class="table-title">
                                <div>交流</div>
                                <div>电压</div>
                                <div>电流</div>
                                <div>频率</div>
                            </div>
                            <div
                                    class="table-content"
                                    v-for="communication in communicationList"
                            >
                                <div>{{ communication.title }}</div>
                                <div>{{ communication.voltage }}V</div>
                                <div>{{ communication.current }}A</div>
                                <div>{{ communication.rate }}Hz</div>
                            </div>
                        </div>
                    </div>
                    <div class="power-msg">
                        <div class="grid-box grid-power">
                            <div class="one-msg" v-for="item in powerList">
                                <span>{{ item.title }}</span>
                                <span class="one-detail"
                                >{{ item.value }}{{ item.unit ? item.unit : "" }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 电网 -->
        <!-- <div class="row-box">
          <div class="content-box">
            <div class="title-box">
              <span>电网</span>
              <div class="up-down" @click="changeFlag(3)">
                {{ getInclude(3) ? "展开" : "收起"
                }}<a-icon type="up" :class="getInclude(3) ? 'roteIcon' : ''" />
              </div>
            </div>
            <div :class="getInclude(3) ? 'pack-up' : 'updown-box'">
              <div class="power-msg">
                <div class="grid-box grid-power">
                  <div class="one-msg" v-for="item in powerList">
                    <span>{{ item.title }}</span>
                    <span class="one-detail">{{ item.value }} {{ item.unit }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- 用电 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>用电</span>
                    <div class="up-down" @click="changeFlag(4)">
                        {{ getInclude(4) ? "展开" : "收起"
                        }}
                        <a-icon type="up" :class="getInclude(4) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(4) ? 'pack-up' : 'updown-box'">
                    <div class="power-msg">
                        <div class="grid-box grid-power">
                            <div class="one-msg">
                                <span>累计用电量：</span>
                                <span class="one-detail">19.02718 MWh</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 温度 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>温度</span>
                    <div class="up-down" @click="changeFlag(5)">
                        {{ getInclude(5) ? "展开" : "收起"
                        }}
                        <a-icon type="up" :class="getInclude(5) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(5) ? 'pack-up' : 'updown-box'">
                    <div class="power-msg">
                        <div class="grid-box grid-power">
                            <div class="one-msg" v-for="item in temperature">
                                <span>{{ item.title }}</span>
                                <span class="one-detail">{{ item.value }} {{ item.unit }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 其他 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>其他</span>
                    <div class="up-down" @click="changeFlag(6)">
                        {{ getInclude(6) ? "展开" : "收起"
                        }}
                        <a-icon type="up" :class="getInclude(6) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(6) ? 'pack-up' : 'updown-box'">
                    <div class="power-msg">
                        <div class="grid-box grid-power">
                            <div class="one-msg" v-for="item in other">
                                <span>{{ item.title }}</span>
                                <span class="one-detail">{{ item.value }} {{ item.unit }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 报警 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>报警</span>
                    <div class="up-down" @click="changeFlag(7)">
                        {{ getInclude(7) ? "展开" : "收起"
                        }}
                        <a-icon type="up" :class="getInclude(7) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(7) ? 'pack-up' : 'updown-box'">
                    <div class="power-msg">
                        <div class="grid-box grid-power">
                            <div class="one-msg">
                                <span>故障代码：</span>
                                <span class="one-detail">----</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 历史数据 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>历史数据</span>
                    <div class="up-down" @click="changeFlag(8)">
                        {{ getInclude(8) ? "展开" : "收起"
                        }}
                        <a-icon type="up" :class="getInclude(8) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(8) ? 'pack-up' : 'updown-box'">
                    <div class="power-msg flex-end">
                        <div class="right-content">
                            <template v-if="templateActive != 5">
                                <div class="time-more">
                                    <div
                                            v-for="t in timeTypeList"
                                            :class="t.value == activeTime ? 'active-time' : ''"
                                            @click="changeTimeType(t.value)"
                                    >
                                        {{ t.label }}
                                    </div>
                                </div>
                                <!-- <div class="btn-operation ml-16">参数选择</div> -->
                                <div class="btn-operation ml-16" @click="exportReport">
                                    导出报表
                                </div>

                                <div class="time-picker">
                                    <!-- <a-icon type="left" @click="dealTime(-1)" /> -->
                                    <a-date-picker
                                            class="picker"
                                            :allowClear="false"
                                            v-model="historyTime"
                                            v-if="activeTime == 1"
                                    />
                                    <a-week-picker
                                            :allowClear="false"
                                            v-model="historyTime"
                                            v-if="activeTime == 2"
                                    />
                                    <a-month-picker
                                            :allowClear="false"
                                            v-model="historyTime"
                                            v-if="activeTime == 3"
                                    />
                                    <a-date-picker
                                            class="picker"
                                            :allowClear="false"
                                            v-model="historyTime"
                                            format="YYYY"
                                            mode="year"
                                            :open="orderOpen"
                                            @openChange="openOrder"
                                            @panelChange="panelOrder"
                                            v-if="activeTime == 4"
                                    />

                                    <!-- <a-icon type="right" @click="dealTime(1)" /> -->
                                </div>
                            </template>
                            <div class="time-picker" v-else>
                                <a-date-picker
                                        class="picker"
                                        :allowClear="false"
                                        v-model="rateTime"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="sys-template" v-if="activeTime < 3">
                        <span>系统模板：</span>
                        <div
                                class="btn-operation ml-16 template-style"
                                :class="tem.value == templateActive ? 'choose-template' : ''"
                                v-for="tem in templateList"
                                @click="changeTemplate(tem.value)"
                        >
                            {{ tem.label }}
                        </div>
                        <!-- <span class="how-tit">如何创建我的模板？</span> -->
                    </div>

                    <template v-if="templateActive != 5">
                        <div id="historyE"></div>
                    </template>

                    <div v-else class="power-list">
                        <a-empty v-if="allPowerData.length == 0"></a-empty>
                        <div class="list-box" v-else>
                            <div class="title-table">
                                <div class="tit">时间</div>
                                <div class="tit">总直流功率(KW)</div>
                                <div class="tit">总有功功率(KW)</div>
                                <div class="tit">发电量</div>
                            </div>
                            <div class="all-power">
                                <div class="one-power" v-for="row in allPowerData">
                                    <div class="one-content" v-for="column in row">
                                        {{ column }}
                                    </div>
                                </div>
                                <!-- <div class="scroll-box">

                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {lineEcharts} from "../config/historyEcharts";
    import {powerHistoryEchart} from "../../homeData/index";
    import {dcEcharts, dcAorVEcharts} from "../config/dcEcharts";
    import moment from "moment";
    import {
        queryInverterDetails,
        deviceHistoryData,
        dcHistoryData,
        historyDownload,
        dcPowerList,
    } from "@/api/device";
    import {exportDown} from "@/utils/index";

    export default {
        data() {
            return {
                // 基础信息
                baseInfo: [
                    {
                        title: "SN号：",
                        value: "",
                        prop: "deviceSn",
                    },
                    {
                        title: "产品型号：",
                        value: "",
                        prop: "equipmentTypeCode",
                    },
                    {
                        title: "逆变器单/三相类型：",
                        value: "",
                        prop: "outType",
                    },
                    {
                        title: "安装位置：",
                        value: "--",
                        prop: "childSystem",
                    },
                    {
                        title: "矩阵序号：",
                        value: "--",
                        prop: "matrix",
                    },
                    {
                        title: "额定功率：",
                        value: "",
                        prop: "ratePower",
                        unit: "kW",
                    },
                ],

                //版本信息
                version: [
                    {
                        title: "人机交互版本：",
                        value: "--",
                    },
                    {
                        title: "DSP版本号：",
                        value: "--",
                    },
                ],

                //发电
                directList: [], //直流数据
                communicationList: [], //交流数据
                powerList: [
                    {
                        title: "直流输入总功率：",
                        value: "0",
                        prop: "allDCPower",
                        unit: "W",
                    },
                    {
                        title: "交流输出总功率（有功）：",
                        value: "0",
                        prop: "allActivePower",
                        unit: "W",
                    },
                    {
                        title: "功率因数值：",
                        value: "0",
                        prop: "powerFactor",
                    },
                    {
                        title: "前一日发电量：",
                        value: "0",
                        prop: "yesterdayPower",
                        unit: "kWh",
                    },
                    {
                        title: "累计发电量（有功）：",
                        value: "0",
                        prop: "allPower",
                        unit: "KWh",
                    },
                    {
                        title: "上月发电量（有功）：",
                        value: "0",
                        prop: "lastMonthPower",
                        unit: "KWh",
                    },
                    {
                        title: "当日发电量（有功）：",
                        value: "0",
                        prop: "dayPower",
                        unit: "kWh",
                    },
                    {
                        title: "当月发电量（有功）：",
                        value: "0",
                        prop: "monthPower",
                        unit: "KWh",
                    },
                    {
                        title: "当年发电量（有功）：",
                        value: "0",
                        unit: "KWh",
                        prop: "totalYearAmount",
                    },
                    {
                        title: "总PV电压：",
                        value: "0",
                        prop: "totalVoltage",
                        unit: "V",
                    },
                    {
                        title: "总PV电流：",
                        prop: "totalCurrent",
                        value: "0",
                        unit: "A",
                    },
                    // {
                    //   title: "信号阀：",
                    //   value: "0",
                    // },
                ],

                //温度
                temperature: [
                    {
                        title: "逆变器温度：",
                        value: "",
                        prop: "airTemperature",
                        unit: "℃",
                    },
                    {
                        title: "逆变器模块温度：",
                        value: "--",
                    },
                    {
                        title: "模块温度1：",
                        value: "--",
                    },
                ],

                // 其他
                other: [
                    {
                        value: "",
                        title: "系统时间：",
                        prop: "createTime",
                    },
                    {
                        value: "",
                        title: "绝缘阻抗值2：",
                        prop: "groundImpedance",
                        unit: "GΩ",
                    },
                    {
                        value: "",
                        title: "母线电压1：",
                        prop: "busVoltage",
                        unit: "V",
                    },
                ],

                // 展开收起
                packUpFlag: [],

                // 模板列表
                templateList: [
                    {
                        value: "1",
                        label: "交流分析",
                    },
                    {
                        value: "2",
                        label: "直流电流对比",
                    },
                    {
                        value: "3",
                        label: "直流电压对比",
                    },
                    {
                        value: "4",
                        label: "直流电流和电压对比",
                    },
                    {
                        value: "5",
                        label: "实时功率",
                    },
                ],
                templateActive: "1",
                //历史数据查询时间
                historyTime: moment(),
                timeTypeList: [
                    {
                        value: "1",
                        label: "日",
                    },
                    {
                        value: "2",
                        label: "周",
                    },
                    {
                        value: "3",
                        label: "月",
                    },
                    {
                        value: "4",
                        label: "年",
                    },

                    // {
                    //   value: "5",
                    //   label: "总",
                    // },
                ],
                activeTime: "1",
                orderOpen: false,
                allTime: [moment("2015"), moment("2023")],
                detailList: {},
                chartInstance: null,
                allPowerData: [],
                rateTime: moment(),
            };
        },
        props: {
            currentData: {
                type: Object,
                default: () => {
                },
            },
        },
        methods: {
            getInclude(index) {
                return this.packUpFlag.includes(index);
            },
            changeFlag(index) {
                let findIndex = this.packUpFlag.findIndex((item) => {
                    return item == index;
                });
                if (findIndex == -1) {
                    this.packUpFlag.push(index);
                } else {
                    this.packUpFlag.splice(findIndex, 1);
                }
            },
            initEcharts(data) {
                // const comparebox = document.getElementById("historyE");
                // const compareEchart = this.echarts.init(comparebox);
                this.instance();
                this.chartInstance.setOption(lineEcharts(data.xData, data.seriesData));
                setTimeout(() => {
                    this.chartInstance.resize();
                }, 0);
            },
            dealTime(flag) {
                if (flag == -1) {
                    // this.historyTime =
                }
            },
            changeTimeType(value) {
                this.activeTime = value;
                if (this.activeTime == 3 || this.activeTime == 4) {
                    this.templateActive = 1;
                }
                this.getHistory();
            },
            openOrder(val) {
                if (val) {
                    this.orderOpen = true;
                } else {
                    this.orderOpen = false;
                }
            },
            panelOrder(val) {
                this.historyTime = moment(val);
                this.orderOpen = false;
            },
            getDetail() {
                // queryInverterDetails
                queryInverterDetails({id: this.currentData.id})
                    .then((res) => {
                        this.detailList = res.data;
                        this.dealList(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            dealList(newv) {
                let totalNum = newv.dcList.reduce(
                    (total, current) => {
                        return {
                            totalCurrent: +total.totalCurrent + +current.current,
                            totalVoltage: +total.totalVoltage + +current.voltage,
                        };
                    },
                    {totalCurrent: 0, totalVoltage: 0}
                );
                let baseInfoData = {
                    ...newv.baseInfo,
                    ...newv.inverterSystem,
                    ...totalNum,
                    ...newv,
                };
                this.temperature[0].value = baseInfoData.airTemperature;
                this.directList = newv.dcList;
                this.communicationList = newv.acList;
                this.baseInfo.map((item) => {
                    item.value = isNaN(Number(baseInfoData[item.prop]))
                        ? baseInfoData[item.prop]
                        : Number(baseInfoData[item.prop]).toFixed(1);
                });
                this.powerList.map((item) => {
                    item.value = isNaN(Number(baseInfoData[item.prop]))
                        ? baseInfoData[item.prop]
                        : Number(baseInfoData[item.prop]).toFixed(1);
                });
                this.other.map((item) => {
                    item.value = isNaN(Number(baseInfoData[item.prop]))
                        ? baseInfoData[item.prop]
                        : Number(baseInfoData[item.prop]).toFixed(1);
                });

                // this.initEcharts();
            },
            initBarEcharts(data) {
                this.instance();
                this.chartInstance.setOption(powerHistoryEchart(data, "kWh", "发电量"));
                setTimeout(() => {
                    this.chartInstance.resize();
                }, 0);
            },
            instance() {
                const comparebox = document.getElementById("historyE");
                if (!this.chartInstance) {
                    this.chartInstance = this.echarts.init(comparebox);
                }
            },
            getHistory() {
                // if (this.activeTime == 1 || this.activeTime == 2) {
                // } else {
                //   deviceHistoryData({
                //     id: this.currentData.id,
                //     timeType: this.activeTime,
                //     ...this.getTime(),
                //   })
                //     .then((res) => {
                //       if (this.activeTime == 2 || this.activeTime == 1) {
                //         let dealData = this.dealEchartsData(res.data);
                //         this.initEcharts(dealData);
                //       } else {
                //         console.log(res);
                //         let dealData = this.dealMonthYear(res.data);
                //         this.initBarEcharts(dealData);
                //       }
                //     })
                //     .catch((err) => {});
                // }

                if (this.templateActive == 1) {
                    deviceHistoryData({
                        id: this.currentData.id,
                        timeType: this.activeTime,
                        ...this.getTime(),
                    })
                        .then((res) => {
                            if (this.activeTime == 2 || this.activeTime == 1) {
                                let dealData = this.dealEchartsData(res.data);
                                console.log(dealData, "---------------------dealData---");
                                this.initEcharts(dealData);
                            } else {
                                console.log(res);
                                let dealData = this.dealMonthYear(res.data);
                                this.initBarEcharts(dealData);
                            }
                        })
                        .catch((err) => {
                        });
                } else if (this.templateActive == 5) {
                    this.getPowerData();
                } else {
                    dcHistoryData({
                        id: this.currentData.id,
                        timeType: this.activeTime,
                        ...this.getTime(),
                    }).then((res) => {
                        this.dealDcData(res.data);
                    });
                }
            },
            getPowerData() {
                dcPowerList({
                    id: this.currentData.id,
                    createTime: moment(this.rateTime).format("YYYY-MM-DD 00:00:00"),
                }).then((res) => {
                    this.allPowerData = res.data.map((item) => {
                        return [
                            item.createTime || "--",
                            item.allDCPower || 0,
                            item.allActivePower || 0,
                            item.dayPower || 0,
                        ];
                    });
                });
            },
            getTime() {
                let startTime, endTime;
                console.log(this.historyTime, this.timeType);
                if (this.activeTime == 1) {
                    startTime = moment(this.historyTime).format("YYYY-MM-DD 00:00:00");
                    endTime = moment(this.historyTime).format("YYYY-MM-DD 00:00:00");
                } else if (this.activeTime == 2) {
                    startTime = moment(this.historyTime)
                        .startOf("week")
                        .format("YYYY-MM-DD 00:00:00");
                    endTime = moment(this.historyTime)
                        .endOf("week")
                        .format("YYYY-MM-DD 00:00:00");
                    console.log("week");
                } else if (this.activeTime == 3) {
                    startTime = moment(this.historyTime)
                        .startOf("month")
                        .format("YYYY-MM-DD 00:00:00");
                    endTime = moment(this.historyTime)
                        .endOf("month")
                        .format("YYYY-MM-DD 00:00:00");
                } else {
                    startTime = moment(this.historyTime)
                        .startOf("year")
                        .format("YYYY-MM-DD 00:00:00");
                    endTime = moment(this.historyTime)
                        .endOf("year")
                        .format("YYYY-MM-DD 00:00:00");
                }
                return {
                    startTime,
                    endTime,
                };
            },
            dealEchartsData(data) {
                if (Object.keys(data).length != 0) {
                    let startTime = this.historyTime;
                    let minTime, maxTime;
                    if (this.activeTime == 1) {
                        minTime = moment(startTime).startOf("day").valueOf();
                        maxTime = moment(startTime).endOf("day").valueOf();
                    } else {
                        minTime = moment(startTime).startOf("week").valueOf();
                        maxTime = moment(startTime).endOf("week").valueOf();
                    }

                    //交流RUA
                    let Rvoltage = [],
                        Rcurrent = [],
                        Rrate = [];
                    // 交流SVB
                    let Svoltage = [],
                        Scurrent = [];
                    // 交流TWC
                    let Tvoltage = [],
                        Tcurrent = [];

                    let activePowerData = data.allActivePower;
                    delete data.allActivePower;
                    console.log(data);
                    //交流总功率
                    let activePower = [];
                    for (let key in data) {
                        data[key].forEach((item) => {
                            if (item.title == "R") {
                                Rvoltage.push([item.createTime, item.voltage]);
                                Rcurrent.push([item.createTime, item.current]);
                                Rrate.push([item.createTime, item.rate]);
                            } else if (item.title == "S") {
                                Svoltage.push([item.createTime, item.voltage]);
                                Scurrent.push([item.createTime, item.current]);
                            } else if (item.title == "T") {
                                Tvoltage.push([item.createTime, item.voltage]);
                                Tcurrent.push([item.createTime, item.current]);
                            }
                        });
                    }
                    activePowerData.forEach((item) => {
                        activePower.push([item.createTime, item.allActivePower]);
                    });
                    Rvoltage.sort((a, b) => {
                        return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                    });
                    // console.log(Rvoltage, "--------Rvoltage----", activePower);
                    minTime = moment(Rvoltage[0][0]).valueOf();
                    let index = Rvoltage.length - 1;
                    maxTime = moment(Rvoltage[index][0]).valueOf();
                    return {
                        xData: {
                            min: minTime,
                            max: maxTime,
                        },
                        seriesData: [
                            Rvoltage,
                            Svoltage.sort((a, b) => {
                                return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                            }),
                            Tvoltage.sort((a, b) => {
                                return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                            }),
                            Rcurrent.sort((a, b) => {
                                return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                            }),
                            Scurrent.sort((a, b) => {
                                return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                            }),
                            Tcurrent.sort((a, b) => {
                                return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                            }),
                            Rrate.sort((a, b) => {
                                return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                            }),
                            activePower.sort((a, b) => {
                                return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                            }),
                        ],
                    };
                }
            },
            dealMonthYear(data) {
                let xData = [],
                    yData = [];
                let arrData = [];

                for (let k in data) {
                    arrData.push({time: k, value: data[k]});
                }
                arrData.sort((a, b) => {
                    return moment(a.time).valueOf() - moment(b.time).valueOf();
                });
                arrData.map((item) => {
                    xData.push(moment(item.time).format("YYYY-MM-DD"));
                    yData.push(item.value);
                });
                return {
                    xData,
                    yData,
                };
            },
            changeTemplate(index) {
                if (index == this.templateActive) {
                    return;
                }
                this.templateActive = index;
                if (this.templateActive == 5 && this.chartInstance) {
                    this.chartInstance.dispose();
                    this.chartInstance = null;
                }
                this.$nextTick(() => {
                    this.getHistory();
                });
            },
            dealDcData(data) {
                if (Object.keys(data).length != 0) {
                    let minTime, maxTime;
                    if (this.activeTime == 1) {
                        minTime = moment(this.historyTime).startOf("day").valueOf();
                        maxTime = moment(this.historyTime).endOf("day").valueOf();
                    } else {
                        minTime = moment(this.historyTime).startOf("week").valueOf();
                        maxTime = moment(this.historyTime).endOf("week").valueOf();
                    }
                    let legend = [];
                    let yData = [];
                    let flag = 0;
                    let legendText =
                        this.templateActive == 2
                            ? "直流电流"
                            : this.templateActive == 3
                            ? "直流电压"
                            : ["直流电流", "直流电压"];
                    for (let k in data) {
                        data[k].forEach((dc) => {
                            if (flag == 0) {
                                if (this.templateActive == 4) {
                                    legend.push(
                                        ...[legendText[0] + dc.title, legendText[1] + dc.title]
                                    );
                                    yData.push(
                                        ...[
                                            {
                                                name: legendText[0] + dc.title,
                                                type: "line",
                                                data: [],
                                                // areaStyle: {},
                                                symbol: "none",
                                                smooth: true,
                                                yAxisIndex: 0,
                                            },
                                            {
                                                name: legendText[1] + dc.title,
                                                type: "line",
                                                yAxisIndex: 1,
                                                data: [],
                                                smooth: true,
                                                // areaStyle: {},
                                                symbol: "none",
                                            },
                                        ]
                                    );
                                } else {
                                    legend.push(legendText + dc.title);
                                    yData.push({
                                        name: legendText + dc.title,
                                        type: "line",
                                        data: [],
                                        smooth: true,
                                        // areaStyle: {},
                                        symbol: "none",
                                    });
                                }
                            }

                            yData.forEach((y) => {
                                if (this.templateActive == 2) {
                                    // let dataTime = data[k].map((item) => {
                                    //   return [item.createTime, item.current];
                                    // });
                                    if (y.name == legendText + dc.title) {
                                        y.data.push([dc.createTime, dc.current]);
                                    }
                                } else if (this.templateActive == 3) {
                                    if (y.name == legendText + dc.title) {
                                        y.data.push([dc.createTime, dc.voltage]);
                                    }
                                } else {
                                    if (y.name == legendText[0] + dc.title) {
                                        y.data.push([dc.createTime, dc.voltage]);
                                    }
                                    if (y.name == legendText[1] + dc.title) {
                                        y.data.push([dc.createTime, dc.current]);
                                    }
                                }
                            });
                        });
                        flag++;
                    }

                    // const comparebox = document.getElementById("historyE");
                    // const compareEchart = this.echarts.init(comparebox);

                    // compareEchart.dispose();
                    // compareEchart.clear();
                    this.instance();
                    this.chartInstance.clear();
                    yData.forEach((item) => {
                        item.data.sort((a, b) => {
                            return moment(a[0]).valueOf() - moment(b[0]).valueOf();
                        });
                    });
                    minTime = moment(yData[0].data[0][0]).valueOf();
                    let index = yData[0].data.length - 1;
                    maxTime = moment(yData[0].data[index][0]).valueOf();
                    console.log(yData[0].data[index][0], "------legend-----", yData[0]);
                    if (this.templateActive == 4) {
                        this.chartInstance.setOption(
                            dcAorVEcharts({min: minTime, max: maxTime}, legend, yData)
                        );
                    } else {
                        this.chartInstance.setOption(
                            dcEcharts({min: minTime, max: maxTime}, legend, yData)
                        );
                    }

                    console.log(dcEcharts({min: minTime, max: maxTime}, legend, yData));
                    setTimeout(() => {
                        this.chartInstance.resize();
                    }, 0);
                }
            },
            exportReport() {
                let startTime, endTime;
                if (this.activeTime == 1) {
                    startTime = moment().startOf("day").format("YYYY-MM-DD 00:00:00");
                    endTime = moment().endOf("day").format("YYYY-MM-DD 00:00:00");
                } else if (this.activeTime == 2) {
                    startTime = moment().startOf("week").format("YYYY-MM-DD 00:00:00");
                    endTime = moment().endOf("week").format("YYYY-MM-DD 00:00:00");
                } else if (this.activeTime == 3) {
                    startTime = moment().startOf("month").format("YYYY-MM-DD 00:00:00");
                    endTime = moment().endOf("month").format("YYYY-MM-DD 00:00:00");
                } else {
                    startTime = moment().startOf("year").format("YYYY-MM-DD 00:00:00");
                    endTime = moment().endOf("year").format("YYYY-MM-DD 00:00:00");
                }

                let requestData = {
                    id: this.currentData.id,
                    timeType: this.activeTime,
                    startTime,
                    endTime,
                };
                historyDownload(requestData).then((res) => {
                    exportDown(res, "历史数据");
                    this.$message.success("导出数据成功");
                });
            },
        },
        watch: {
            historyTime(newv) {
                this.getHistory();
                console.log(newv);
            },
            rateTime(newv) {
                this.getPowerData();
            },
        },
        computed: {},
        mounted() {
            this.getDetail();
            this.getHistory();
        },
    };
</script>
<style lang="less" scoped>
    .max-container {
        .row-box {
            position: relative;
            border: 1px solid rgba(37, 43, 58, 0.1);
            border-radius: 4px;
            background: #fff;
            padding: 16px 0;
            box-sizing: border-box;
            box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
            margin-bottom: 8px;

            .content-box {
                padding: 0 16px;

                // 标题
                .title-box {
                    display: flex;
                    justify-content: space-between;

                    span {
                        font-size: 16px;
                        color: #252b3a;
                    }

                    .up-down {
                        font-size: 12px;
                        color: #048fff;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        i {
                            font-size: 16px;
                        }

                        .roteIcon {
                            transform: rotate(180deg);
                        }
                    }
                }

                .content-msg {
                    .grid-box {
                        display: grid;

                        margin-top: 16px;

                        .one-msg {
                            font-size: 12px;
                            color: #00000073;

                            .one-detail {
                                color: #515561;
                            }
                        }
                    }

                    .grid-base {
                        grid-template-rows: 1fr 1fr;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-row-gap: 16px;
                    }

                    .grid-version {
                        grid-template-columns: 1fr 1fr 1fr;
                    }

                    .table-box {
                        width: 100%;
                        display: grid;
                        grid-row-gap: 16px;
                        height: max-content;

                        .table-title,
                        .table-content {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            font-size: 12px;
                            color: #048fff;

                            div {
                                text-align: center;
                            }
                        }

                        .table-title {
                            div {
                                color: #95929c;
                            }
                        }
                    }

                    .center-box {
                        display: flex;
                        justify-content: center;

                        .progress-box {
                            width: 90%;
                            height: 10px;
                            position: relative;
                            background: #cde9ff;
                            border-radius: 4px;
                            @keyframes widthAdd {
                                from {
                                    width: 0;
                                }
                                to {
                                    width: 100%;
                                }
                            }

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 0;
                                animation: widthAdd 4s linear infinite;
                                border-radius: 4px;
                                height: 100%;
                                background: #048fff;
                            }

                            .square-box {
                                width: 56px;
                                height: 56px;
                                padding: 5px;
                                box-sizing: border-box;
                                position: absolute;
                                left: 50%;
                                top: 0;
                                background: #fff;
                                transform: translateY(-40%);

                                .square-inner {
                                    width: 100%;
                                    height: 100%;
                                    background: #048fff;
                                    border-radius: 4px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: #fff;

                                    .square-ctn {
                                        display: flex;
                                        flex-direction: column;
                                        font-size: 12px;
                                        align-items: center;

                                        .small {
                                            transform: scale(0.8);
                                            font-weight: 700;
                                        }

                                        .line {
                                            width: 70%;
                                            height: 1px;
                                            background: #fff;
                                        }

                                        .bolang {
                                            font-size: 20px;
                                            line-height: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .updown-box {
                    overflow: hidden;
                    max-height: 3000px;
                }

                .pack-up {
                    overflow: hidden;
                    height: 0;
                }

                //发电信息
                .btn-operation {
                    padding: 0 14px;
                    background-color: #fff;
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    font-size: 12px;
                    height: 22px;
                    line-height: 20px;
                    color: #00000073;
                    cursor: pointer;
                    transition: all 0.5s;

                    &:hover {
                        border-color: #048fff;
                        color: #048fff;
                    }
                }

                .power-msg {
                    box-sizing: border-box;
                    padding: 0 20px;
                    margin-top: 20px;

                    .grid-box {
                        display: grid;
                        margin-top: 16px;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-row-gap: 16px;

                        .one-msg {
                            font-size: 12px;
                            color: #00000073;

                            .one-detail {
                                color: #515561;
                            }
                        }
                    }

                    .right-content {
                        display: flex;
                        align-items: center;

                        .time-more {
                            height: 24px;
                            display: flex;

                            div {
                                border: 1px solid #d9d9d9;
                                padding: 0 8px;
                                font-size: 12px;
                                height: 100%;
                                line-height: 22px;
                                cursor: pointer;

                                &:hover {
                                    color: #048fff;
                                    background-color: #048fff1a;
                                    border-color: #048fff;
                                }
                            }

                            .active-time {
                                color: #048fff;
                                background-color: #048fff1a;
                                border-color: #048fff;
                            }
                        }

                        .time-picker {
                            margin-left: 8px;
                            cursor: pointer;

                            &:hover {
                                color: #048fff;
                            }

                            .picker {
                                margin: 0 6px;
                            }

                            .ant-calendar-picker {
                                /deep/ .ant-calendar-picker-input {
                                    width: 128px;
                                    height: 30px;
                                    font-size: 12px;
                                }

                                // height: 24px;
                            }

                            .rang-picker {
                                /deep/ .ant-calendar-picker-input {
                                    width: 148px;
                                }
                            }
                        }
                    }
                }

                .sys-template {
                    height: 48px;
                    margin-top: 8px;
                    background-color: #f8f8f8;
                    padding: 0 16px;
                    color: #515561;
                    display: flex;
                    font-size: 12px;
                    align-items: center;

                    .how-tit {
                        color: #048fff;
                        cursor: pointer;
                        margin-left: 24px;
                    }

                    .choose-template {
                        color: #048fff;
                        border-color: #048fff;
                        background-color: #048fff1a;
                    }

                    .template-style {
                        &:hover {
                            color: #048fff;
                            border-color: #048fff;
                            background-color: #048fff1a;
                        }
                    }
                }

                #historyE {
                    height: 384px;
                    width: 100%;
                }

                .power-list {
                    height: 450px;
                    width: 100%;
                    overflow-y: auto;

                    .list-box {
                        width: 70%;
                        height: 100%;
                        margin: 0 auto;
                        display: flex;
                        flex-direction: column;

                        .title-table {
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                            height: 50px;

                            .tit {
                                padding: 10px 20px;
                                text-align: center;
                                font-size: 14px;
                                color: #000;
                                font-weight: 600;
                            }
                        }

                        .all-power {
                            flex-grow: 1;
                            max-height: 400px;
                            // over
                            overflow-y: auto;
                            // // overflow: hidden;
                            .one-power {
                                display: grid;
                                overflow-y: auto;
                                grid-template-columns: repeat(4, 1fr);

                                .one-content {
                                    padding: 10px 20px;
                                    text-align: center;
                                    font-size: 12px;
                                    color: #515151;
                                    font-weight: 400;
                                }
                            }

                            .scroll-box {
                                // height: 100%;
                                // overflow-y: auto;
                            }
                        }
                    }
                }

                .flex-end {
                    display: flex;
                    justify-content: flex-end;
                }

                .flex-box {
                    display: flex;
                    align-items: center;

                    div {
                        flex: 1;
                    }
                }
            }
        }
    }
</style>
