<template>
	<div class="max-container">
		<!-- 发电热力图 -->
		<div class="row-box">
			<div class="content-box">
				<div class="title-box">
					<span>发电热力图</span>
					<div class="up-down" @click="changeFlag(0)">
						{{
							getInclude ( 0 ) ? "展开" : "收起"
						}}
						<a-icon type="up" :class="getInclude(0) ? 'roteIcon' : ''"/>
					</div>
				</div>
				<div :class="getInclude(0) ? 'pack-up' : 'updown-box'">
					<div class="power-msg flex-end">
						<div class="right-content">
							<div class="time-more">
								<div
									v-for="t in timeTypeList"
									:class="t.value == activeTime ? 'active-time' : ''"
									@click="changeTimeType(t.value)"
								>
									{{ t.label }}
								</div>
							</div>
							<!-- <div class="btn-operation ml-16">参数选择</div>
							<div class="btn-operation ml-16">导出报表</div> -->
							<div class="time-picker">
								<!-- <a-icon type="left" @click="dealTime(-1)" /> -->
								<a-date-picker
									class="picker"
									:allowClear="false"
									v-model="historyTime"
									@change="changeTime"
								/>
								<!-- <a-icon type="right" @click="dealTime(1)" /> -->
							</div>
						</div>
					</div>
					<div class="echarts-box">
						<a-empty v-if="isEmpty"/>
						<div id="powerHeat" v-else></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moment from "moment";
import echartsConfig from "./config/powerThermalEcharts";
import {monitorAmount} from "@/api/device";

export default {
	data() {
		return {
			// 展开收起
			packUpFlag: [],
			//历史数据查询时间
			historyTime: moment (),
			timeTypeList: [
				{
					value: "1",
					label: "日",
				},
				{
					value: "2",
					label: "月",
				},
				{
					value: "3",
					label: "年",
				},
			],
			activeTime: "1",
			orderOpen: false,
			allTime: [moment ( "2015" ), moment ( "2023" )],
			isEmpty: false,
		};
	},
	props: {
		isReload: {
			type: Boolean,
			default: false,
		},
		currentData: {
			type: Object,
			default: () => {
			},
		},
	},
	methods: {
		getInclude( index ) {
			return this.packUpFlag.includes ( index );
		},
		changeFlag( index ) {
			let findIndex = this.packUpFlag.findIndex ( ( item ) => {
				return item == index;
			} );
			if ( findIndex == -1 ) {
				this.packUpFlag.push ( index );
			} else {
				this.packUpFlag.splice ( findIndex, 1 );
			}
		},

		changeTimeType( value ) {
			this.activeTime = value;
			this.getHeatDetail ();
		},
		initEcharts( xData, yData, seriesData ) {
			this.$nextTick ( () => {
				if ( this.isEmpty ) {
					return;
				}
				const powerHeat = document.getElementById ( "powerHeat", null, {
					devicePixelRatio: window.devicePixelRatio,
				} );
				const powerHeatEchart = this.echarts.init ( powerHeat );
				powerHeatEchart.setOption ( echartsConfig ( xData, yData, seriesData ) );
				setTimeout ( () => {
					powerHeatEchart.resize ();
				}, 0 );
			} );
		},
		// 获取热力图
		getHeatDetail() {
			let data = {
				monitorSn: this.currentData.deviceSn,
				timeType: this.activeTime,
			};
			if ( this.activeTime == 0 ) {
				delete data.timeType;
				data.createTime = moment ( this.historyTime ).format (
					"YYYY-MM-DD 00:00:00"
				);
			}
			monitorAmount ( data )
				.then ( ( res ) => {
					if ( Object.keys ( res.data ).length == 0 ) {
						this.isEmpty = true;
					} else {
						this.isEmpty = false;
					}
					let xData = res.data[0]?.map ( ( item, index ) => {
						return "P" + (index + 1);
					} );
					let yData = [];
					let seriesData = [];
					for ( let key in res.data ) {
						yData.push ( "B" + key );
						res.data[key]?.forEach ( ( item, index ) => {
							seriesData.push ( [index, Number ( key ), Number ( item )] );
						} );
					}
					this.initEcharts ( xData, yData, seriesData );
					// let seriesData =
					console.log ( seriesData );
				} )
				.catch ( ( err ) => {
					console.log ( err );
				} );
		},

		changeTime() {
			this.activeTime = "0";
			this.getHeatDetail ();
		},
	},
	mounted() {
		this.getHeatDetail ();
	},
};
</script>
<style lang="less" scoped>
.max-container {
	.row-box {
		position: relative;
		border: 1px solid rgba(37, 43, 58, 0.1);
		border-radius: 4px;
		background: #fff;
		padding: 16px 0;
		box-sizing: border-box;
		box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
		margin-bottom: 8px;

		.content-box {
			padding: 0 16px;

			// 标题
			.title-box {
				display: flex;
				justify-content: space-between;

				span {
					font-size: 16px;
					color: #252b3a;
				}

				.up-down {
					font-size: 12px;
					color: #048fff;
					cursor: pointer;
					display: flex;
					align-items: center;

					i {
						font-size: 16px;
					}

					.roteIcon {
						transform: rotate(180deg);
					}
				}
			}

			.power-msg {
				box-sizing: border-box;
				padding: 0 20px;
				margin-top: 20px;

				.grid-box {
					display: grid;
					margin-top: 16px;
					grid-template-columns: 1fr 1fr 1fr;
					grid-row-gap: 16px;

					.one-msg {
						font-size: 12px;
						color: #00000073;

						.one-detail {
							color: #515561;
						}
					}
				}

				.right-content {
					display: flex;
					align-items: center;

					.time-more {
						height: 24px;
						display: flex;

						div {
							border: 1px solid #d9d9d9;
							padding: 0 8px;
							font-size: 12px;
							height: 100%;
							line-height: 22px;
							cursor: pointer;

							&:hover {
								color: #048fff;
								background-color: #048fff1a;
								border-color: #048fff;
							}
						}

						.active-time {
							color: #048fff;
							background-color: #048fff1a;
							border-color: #048fff;
						}
					}

					.time-picker {
						margin-left: 8px;
						cursor: pointer;

						&:hover {
							color: #048fff;
						}

						.picker {
							margin: 0 6px;
						}

						.ant-calendar-picker {
							/deep/ .ant-calendar-picker-input {
								width: 128px;
								height: 30px;
								font-size: 12px;
							}

							// height: 24px;
						}

						.rang-picker {
							/deep/ .ant-calendar-picker-input {
								width: 148px;
							}
						}
					}
				}
			}

			.flex-box {
				display: flex;
				align-items: center;

				div {
					flex: 1;
				}
			}

			.flex-end {
				display: flex;
				justify-content: flex-end;
			}

			.echarts-box {
				height: 484px;
				width: 100%;

				#powerHeat {
					height: 484px;
					width: 100%;
				}
			}

			.btn-operation {
				padding: 0 14px;
				background-color: #fff;
				border: 1px solid #d9d9d9;
				border-radius: 4px;
				font-size: 12px;
				height: 22px;
				line-height: 20px;
				color: #00000073;
				cursor: pointer;
				transition: all 0.5s;

				&:hover {
					border-color: #048fff;
					color: #048fff;
				}
			}

			.updown-box {
				overflow: hidden;
				max-height: 3000px;
			}

			.pack-up {
				overflow: hidden;
				height: 0;
			}
		}
	}
}
</style>
