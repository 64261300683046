<template>
  <div class="main-box">
    <div class="head-tit">设备上传数据时反映的实际网络拓扑关系。</div>
    <a-table
      :columns="columns"
      :data-source="dataList"
      v-if="dataList && dataList.length"
      class="components-table-demo-nested"
      :defaultExpandAllRows="true"
    >
      <template #type="text, record">
        <div>
          {{ record.deviceTitle }}
        </div>
        <div>
          {{ record.deviceSn }}
        </div>
      </template>
      <a-table
        slot="expandedRowRender"
        slot-scope="text"
        :columns="innerColumns"
        :data-source="innerData"
        :pagination="false"
      >
        <template #innerType="text, record">
          <div>
            {{ record.deviceTitle }}
          </div>
          <div>
            {{ record.deviceSn }}
          </div>
        </template>
      </a-table>
    </a-table>
  </div>
</template>
<script>
import { deviceConnection } from "@/api/device.js";
export default {
  data() {
    return {
      columns: [
        {
          title: "类型/SN",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
          width: "130",
        },
        {
          title: "状态",
          dataIndex: "workStatus",
          width: "50",
        },
        {
          title: "数据更新时间",
          dataIndex: "createTime",
          width: "80",
        },
      ],
      dataList: [],
      innerData: [],
      innerColumns: [
        {
          title: "类型/SN",
          key: "name",
          scopedSlots: { customRender: "innerType" },
          width: "280",
        },
        {
          title: "状态",
          dataIndex: "workStatus",
          width: "100",
        },
        {
          title: "数据更新时间",
          key: "createTime",
          dataIndex: "createTime",
          width: "120",
        },
      ],
    };
  },
  props: {
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getInfo() {
      deviceConnection({ id: this.currentData.id }).then((res) => {
        console.log(res);
        this.dataList = [res.data];
        res.data.deviceEntities.map((item) => {
          for (let k in item) {
            if (item[k] == null) {
              item[k] = "--";
            }
          }
        });
        this.innerData = res.data.deviceEntities;
      });
    },
  },
  mounted() {
    this.getInfo();
    // console.log(this.currentData, "-----currentData-----");
  },
};
</script>
<style lang="less" scoped>
.main-box {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
  .head-tit {
    font-size: 12px;
    color: #95929c;
  }
}
</style>