<template>
	<div class="max-container">
		<!-- 基础信息 -->
		<div class="row-box">
			<div class="content-box">
				<div class="title-box">
					<span>基础信息</span>
					<div class="up-down" @click="changeFlag(0)">
						{{
							getInclude ( 0 ) ? "展开" : "收起"
						}}
						<a-icon type="up" :class="getInclude(0) ? 'roteIcon' : ''"/>
					</div>
				</div>
				<div :class="getInclude(0) ? 'pack-up' : 'updown-box'">
					<div class="content-msg">
						<div class="grid-box grid-base">
							<div class="one-msg">
								<span>{{ baseInfo.title }}</span>
								<span class="one-detail">{{ baseInfo.value }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 版本信息 -->
		<div class="row-box">
			<div class="content-box">
				<div class="title-box">
					<span>版本信息</span>
					<div class="up-down" @click="changeFlag(1)">
						{{
							getInclude ( 1 ) ? "展开" : "收起"
						}}
						<a-icon type="up" :class="getInclude(1) ? 'roteIcon' : ''"/>
					</div>
				</div>
				<div :class="getInclude(1) ? 'pack-up' : 'updown-box'">
					<div class="content-msg">
						<div class="grid-box grid-version">
							<div class="one-msg">
								<span>{{ version.title }}</span>
								<span class="one-detail">{{ version.value }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 实时信息 -->
		<div class="row-box">
			<div class="content-box">
				<div class="title-box">
					<span>实时信息</span>
					<div class="up-down" @click="changeFlag(2)">
						{{
							getInclude ( 2 ) ? "展开" : "收起"
						}}
						<a-icon type="up" :class="getInclude(2) ? 'roteIcon' : ''"/>
					</div>
				</div>
				<div :class="getInclude(2) ? 'pack-up' : 'updown-box'">
					<div class="content-msg">
						<div class="grid-box grid-real">
							<div class="one-msg" v-for="item in realTime">
								<span>{{ item.title }}</span>
								<span class="one-detail"
								>{{ item.value }}{{ item.unit ? item.unit : "" }}</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			// 基础信息
			baseInfo: {
				title: "内置设备SN：",
				value: "0900000001",
				prop: "deviceSn",
			},

			//版本信息
			version: {
				title: "模块版本号：",
				value: "AF84010102",
			},

			// 实时
			realTime: [
				{
					title: "数据上传周期：",
					value: "5",
					unit: "Min",
					prop: "",
				},
				{
					title: "数据采集周期：",
					value: "5",
					unit: "s",
					prop: "",
				},
				{
					title: "最大连接台数：",
					value: "1",
					unit: "",
					prop: "",
				},
				{
					title: "ICCID/ESN：",
					value: "4A013C22556932FEEE11",
					unit: "",
					prop: "",
				},
				{
					title: "运营商名称：",
					value: "vv.:YMzg+u5kOjJ!6Dco",
					unit: "",
					prop: "",
				},
				{
					title: "基站ID：",
					value: "10523",
					unit: "",
					prop: "",
				},
				{
					title: "信号强度：",
					value: "5",
					unit: "",
					prop: "",
				},
				{
					title: "心跳频率：",
					value: "5",
					unit: "s",
					prop: "",
				},
				{
					title: "采集器累计发送的帧数：",
					value: "5",
					unit: "",
					prop: "",
				},
				{
					title: "采集器累计接收的帧数：",
					value: "5",
					unit: "",
					prop: "",
				},
				{
					title: "GSM/WCDMA基站定位信息LAC：",
					value: "5",
					unit: "",
					prop: "",
				},
			],
			// 展开收起
			packUpFlag: [],
		};
	},
	methods: {
		getInclude( index ) {
			return this.packUpFlag.includes ( index );
		},
		changeFlag( index ) {
			let findIndex = this.packUpFlag.findIndex ( ( item ) => {
				return item == index;
			} );
			if ( findIndex == -1 ) {
				this.packUpFlag.push ( index );
			} else {
				this.packUpFlag.splice ( findIndex, 1 );
			}
		},
	},
};
</script>
<style lang="less" scoped>
.max-container {
	.row-box {
		position: relative;
		border: 1px solid rgba(37, 43, 58, 0.1);
		border-radius: 4px;
		background: #fff;
		padding: 16px 0;
		box-sizing: border-box;
		box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
		margin-bottom: 8px;

		.content-box {
			padding: 0 16px;

			// 标题
			.title-box {
				display: flex;
				justify-content: space-between;

				span {
					font-size: 16px;
					color: #252b3a;
				}

				.up-down {
					font-size: 12px;
					color: #048fff;
					cursor: pointer;
					display: flex;
					align-items: center;

					i {
						font-size: 16px;
					}

					.roteIcon {
						transform: rotate(180deg);
					}
				}
			}

			.content-msg {
				.grid-box {
					display: grid;

					margin-top: 16px;

					.one-msg {
						font-size: 12px;
						color: #00000073;

						.one-detail {
							color: #515561;
						}
					}
				}

				.grid-base {
					grid-template-rows: 1fr 1fr;
					grid-template-columns: 1fr 1fr 1fr;
					grid-row-gap: 16px;
				}

				.grid-real {
					grid-template-columns: 1fr 1fr 1fr;
					grid-row-gap: 16px;
				}

				.grid-version {
					grid-template-columns: 1fr 1fr 1fr;
				}

				.table-box {
					width: 100%;
					display: grid;
					grid-row-gap: 16px;
					height: max-content;

					.table-title,
					.table-content {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr 1fr;
						font-size: 12px;
						color: #048fff;

						div {
							text-align: center;
						}
					}

					.table-title {
						div {
							color: #95929c;
						}
					}
				}

				.center-box {
					display: flex;
					justify-content: center;

					.progress-box {
						width: 90%;
						height: 10px;
						position: relative;
						background: #cde9ff;
						border-radius: 4px;
						@keyframes widthAdd {
							from {
								width: 0;
							}
							to {
								width: 100%;
							}
						}

						&::before {
							content: "";
							display: block;
							position: absolute;
							left: 0;
							top: 0;
							width: 0;
							animation: widthAdd 4s linear infinite;
							border-radius: 4px;
							height: 100%;
							background: #048fff;
						}

						.square-box {
							width: 56px;
							height: 56px;
							padding: 5px;
							box-sizing: border-box;
							position: absolute;
							left: 50%;
							top: 0;
							background: #fff;
							transform: translateY(-40%);

							.square-inner {
								width: 100%;
								height: 100%;
								background: #048fff;
								border-radius: 4px;
								display: flex;
								justify-content: center;
								align-items: center;
								color: #fff;

								.square-ctn {
									display: flex;
									flex-direction: column;
									font-size: 12px;
									align-items: center;

									.small {
										transform: scale(0.8);
										font-weight: 700;
									}

									.line {
										width: 70%;
										height: 1px;
										background: #fff;
									}

									.bolang {
										font-size: 20px;
										line-height: 10px;
									}
								}
							}
						}
					}
				}
			}

			.updown-box {
				overflow: hidden;
				max-height: 3000px;
			}

			.pack-up {
				overflow: hidden;
				height: 0;
			}
		}
	}
}
</style>
