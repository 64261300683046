
import { requestToken } from '@/utils/request.js'
// 查询电站报警信息 /alarm/queryList
export const queryAlarmList = (data) => {
    return requestToken('/alarm/queryList', data)
}

// 报警设置 /alarmNotice/saveSingle
export const alarmNotice = (data) => {
    return requestToken('/alarmNotice/saveSingle', data)
}

// 查询报警通知 /alarmNotice/querySingle
export const queryalarmNotice = (data) => {
    return requestToken('/alarmNotice/querySingle', data)
}

// 报警历史数据 /alarm/alarmHistoryData
export const alarmHistoryData = (data) => {
    return requestToken('/alarm/alarmHistoryData', data)
}