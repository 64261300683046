import { number } from "echarts"
import moment from 'moment'
// 电站通讯状态简报
export const statusEchartData = (echartData, unit, legend) => {
  let dataName = echartData.powerStation.map(item => {
    return item.name
  })
  return {
    tooltip: {
      trigger: 'item',
      position: 'right'
    },
    // 更改图例默认颜色(依次应用)
    color: ['rgb(0, 157, 246)', 'rgb(253, 186, 56)', 'rgb(164, 68, 225)', 'rgb(73, 236, 227)'],
    legend: {
      show: false,
      data: dataName,
      selectedMode: false,
      orient: 'vertical',
      right: '0',
      top: 'center',
      align: 'left',
      textStyle: {
        color: '#fff'
      }
    },
    title: [{
      text: '电站总数',
      left: 'center',
      y: '35%',
      textStyle: {
        fontSize: 16,
        fontWeight: '400',
        color: '#fff',
        textAlign: 'center'
      },
    }, {
      text: echartData.totalStation,
      left: 'center',
      top: '52%',
      textStyle: {
        fontSize: 14,
        fontWeight: '400',
        color: '#fff',
        textAlign: 'center'
      },
    }],
    // graphic: [ //为环形图中间添加文字
    //   {
    //     type: "text",
    //     left: "50%",
    //     top: "50%",
    //     style: {
    //       text: '电站总数',
    //       textAlign: "center",
    //       fill: "#fff",
    //       fontSize: 16,
    //     },
    //   },
    //   {
    //     type: "text",
    //     left: "30%",
    //     top: "55%",
    //     style: {
    //       text: echartData.totalStation,
    //       textAlign: "center",
    //       fill: "#fff",
    //       fontSize: 14,
    //     },
    //   }
    // ],
    series: [{
      // 设置成绝对的像素值
      type: 'pie',
      width: '100%',
      // center: ['39%', '50%'],
      radius: ['70%', '90%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'right'
      },
      labelLine: {
        show: false
      },
      data: echartData.powerStation
    }]
  }
}

// 整体发电概况 一个圆环
export const overviewEchartData = (echartData, unit, legend) => {
  return {
    tooltip: {
      show: false,
      trigger: 'item'
    },
    // 更改图例默认颜色(依次应用)
    color: ['rgb(0, 157, 246)', 'rgb(253, 186, 56)', 'rgb(164, 68, 225)', 'rgb(73, 236, 227)'],
    legend: {
      show: false,
    },
    series: [{
      // 设置成绝对的像素值
      type: 'pie',
      radius: ['70%', '90%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      hoverAnimation: false,
      data: [
        {
          name: '比例',
          value: echartData,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 1,
              x2: 0,
              y2: 0,
              colorStops: [{
                offset: 0,
                color: '#356eff' // 0% 处的颜色
              }, {
                offset: 1,
                color: '#9bfcaf' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },
          }
        },
        {
          value: 100 - echartData,
          itemStyle: {
            color: 'rgba(0, 0, 0, .5)',
          },
        }
      ],
    }]
  }
}

// 整体发电概况 四个圆环
export const overviewEchartData4 = (echartData, unit, legend) => {
  return {
    tooltip: {
      show: false,
      trigger: 'item'
    },
    // 更改图例默认颜色(依次应用)
    // color: ['rgb(0, 157, 246)', 'rgb(253, 186, 56)', 'rgb(164, 68, 225)', 'rgb(73, 236, 227)'],
    legend: {
      show: false,
    },
    series: [{
      type: 'pie',
      radius: ['85%', '95%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      hoverAnimation: false,
      data: [
        {
          name: '累计发电量',
          value: parseInt(echartData.allPower / 100),
          itemStyle: {
            normal: {
              color: '#0979f3'
            }
          }
        },
        {
          value: 100 - parseInt(echartData.allPower / 100),
          itemStyle: {
            color: 'rgba(0, 0, 0, .5);',
          }
        }
      ],
    }, {
      type: 'pie',
      radius: ['65%', '75%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      hoverAnimation: false,
      data: [
        {
          name: '当年发电量',
          value: parseInt(echartData.yearPower / 100),
          itemStyle: {
            normal: {
              color: '#5a55f4'
            }
          }
        },
        {
          value: 100 - parseInt(echartData.yearPower / 100),
          itemStyle: {
            color: 'rgba(0, 0, 0, .5);',
          }
        }
      ],
    }, {
      type: 'pie',
      radius: ['45%', '55%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      hoverAnimation: false,
      data: [
        {
          name: '当月发电量',
          value: parseInt(echartData.monthPower / 100),
          itemStyle: {
            normal: {
              color: '#3ae9f1'
            }
          }
        },
        {
          value: 100 - parseInt(echartData.monthPower / 100),
          itemStyle: {
            color: 'rgba(0, 0, 0, .5);',
          }
        }
      ],
    }, {
      type: 'pie',
      radius: ['20%', '35%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      hoverAnimation: false,
      data: [
        {
          name: '当日发电量',
          value: parseInt(echartData.dayPower / 100),
          itemStyle: {
            normal: {
              color: '#12cf94'
            }
          }
        },
        {
          value: 100 - parseInt(echartData.dayPower / 100),
          itemStyle: {
            color: 'rgba(0, 0, 0, .5);',
          }
        }
      ],
    }]
  }
}

// 整体当月/当年发电历史
export const powerHistoryEchart = (echartData, unit, legend, total = 0) => {
  return {
    title: {
      text: `${legend}: ${total}kWp`,
      left: 'center',
      top: '5',
      textStyle: {
        fontSize: 17,
        color: '#fff'
      }
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: "category",
      data: echartData.xData,
      axisTick: {
        show: false
      },
      axisLabel: {
        fontSize: 11
      }
    },
    grid: {
      left: "60px",
      right: "10px",
      bottom: "35px",
      top: "50px",
      // containLabel: true,
    },
    yAxis: {
      type: "value",
      name: unit,
      nameTextStyle: {
        align: "left",
        padding: [0, 0, 3, -30],
        // fontSize: '11'
      },
      axisLabel: {
        fontSize: '12'
      },
      splitNumber: 2
    },
    legend: {
      data: [legend],
      bottom: "4",
      itemWidth: '10',
      itemHeight: '10',
      itemStyle: {
        color: '#048FFF'
      },
      align: 'right',
      textStyle: {
        color: '#95929c',
        fontSize: '11',
      },
      padding: [5, 0, 0, 0]

    },
    series: [{
      data: echartData.yData,
      type: "bar",
      backgroundStyle: {
        color: "#1DA8FF",
      },
      itemStyle: {
        color: "#048FFF",
        borderRadius: [4, 4, 0, 0],
      },
      barWidth: "15",
      name: legend,
      tooltip: {
        valueFormatter: function (value) {
          return value + unit;
        }
      },
    }],
  }
}

// 整体功率归一化
export const powerSurveyEchart = (echartData) => {
  return {
    series: [{
      type: "liquidFill",
      radius: "78.1%",
      center: ["50%", "50%"],
      color: ["#26BBAC", "#26BBAC", "#26BBAC"],
      data: [echartData, echartData, echartData], // data个数代表波浪数
      amplitude: 5,
      // 图形样式
      itemStyle: {
        opacity: 1, // 波浪的透明度
        shadowBlur: 0, // 波浪的阴影范围
      },
      backgroundStyle: {
        borderWidth: 2,
        borderColor: "#176867",
        color: "#030915",
      },
      label: {
        show: true,
        textStyle: {
          color: "#fff",
          insideColor: "#fff",
          fontSize: 15,
        },
        formatter: (params) => {
          return `${(params.value * 100).toFixed(2)}%`;
        },
      },
      outline: {
        show: false,
      },
    },],
  }
}

// 电站、容量数据统计 环形图
export const ringGraphEchart = (yData) => {
  return {
    tooltip: {
      trigger: 'item'
    },
    title: {
      text: '电站总数',
      top: 'center',
      left: 'center',
      textStyle: {
        color: '#fff',
        fontSize: '13'
      }
    },
    series: [{
      // name: 'Access From',
      type: 'pie',
      radius: ['70%', '90%'],
      avoidLabelOverlap: false,

      itemStyle: {
        borderCap: "round",
        borderWidth: 6,
        borderColor: '#204278',
        color: (params) => {
          return params.data.color
        }
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          // show: true,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: true
      },
      data: yData
    }]
  };
}

// 装机容量统计
export const capacityEchartData = (echartData, unit, legend) => {
  let dataName = echartData.powerStation.map(item => {
    return item.name
  })
  return {
    tooltip: {
      trigger: 'item'
    },
    // 更改图例默认颜色(依次应用)
    color: ['rgb(0, 157, 246)', 'rgb(253, 186, 56)', 'rgb(164, 68, 225)', 'rgb(73, 236, 227)'],
    legend: {
      data: dataName,
      selectedMode: false,
      orient: 'vertical',
      right: '0',
      top: 'center',
      align: 'left',
      textStyle: {
        color: '#fff'
      }
    },
    graphic: [ //为环形图中间添加文字
      {
        type: "text",
        left: "26%",
        top: "40%",
        style: {
          text: '电站总数',
          textAlign: "center",
          fill: "#fff",
          fontSize: 12,
        },
      },
      {
        type: "text",
        left: "31%",
        top: "55%",
        style: {
          text: echartData.totalStation,
          textAlign: "center",
          fill: "#fff",
          fontSize: 12,
        },
      }
    ],
    series: [{
      // 设置成绝对的像素值
      type: 'pie',
      width: '300',
      center: ['39%', '50%'],
      radius: ['70%', '90%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      itemStyle: {
        borderCap: "round",
        borderWidth: 6,
        borderColor: '#204278',
      },
      data: echartData.powerStation
    }]
  }
}


// 新增工单趋势
export const orderTrendEchart = (echartData = []) => {
  const firstDayOfMonth = moment().startOf('month').valueOf();

  // 获取当月的最后一天的时间戳
  const lastDayOfMonth = moment().endOf('month').valueOf();
  return {
    tooltip: {
      trigger: 'axis',
    },

    xAxis: {
      type: 'time',
      // interval: 24 * 60 * 60 * 1000,
      min: firstDayOfMonth,
      max: lastDayOfMonth,
      axisLabel: {
        show: true,
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10
      },
      axisTick: {
        show: false
      },
    },

    grid: {
      left: "20px",
      right: "0px",
      bottom: "40px",
      top: "10px",
      // containLabel: true,
    },
    yAxis: {
      type: 'value',
      splitNumber: 2
    },
    legend: {
      data: ["当日新增工单"],
      bottom: "4",
      itemWidth: '10',
      itemHeight: '10',
      itemStyle: {
        color: '#048FFF'
      },
      align: 'right',
      textStyle: {
        color: '#95929c',
        fontSize: '11',
      },
      icon: 'circle',
      padding: [5, 0, 0, 0]
    },
    series: [
      {
        data: echartData,
        type: 'line',
        symbol: 'circle',   //将小圆点改成实心 不写symbol默认空心
        symbolSize: 8,    //小圆点的大小
        itemStyle: {
          color: '#048FFF'    //小圆点和线的颜色
        },
        name: '当日新增工单',

      }
    ]
  };
}

// 整体计划发电
export const planEchart = (allData, xData, yData) => {
  return {
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: "50px",
      right: "30px",
      bottom: "50px",
      top: "40px",
      // containLabel: true,
    },
    title: {
      text: `当年发电量${allData}kwh`,
      top: '5',
      left: 'center',
      textStyle: {
        fontSize: 17,
        color: '#fff'
      }
    },
    legend: {
      data: ['当月发电量', '当月计划发电量', '当月计划完成率'],
      itemWidth: 6,
      itemHeight: 3,
      bottom: "0",
      textStyle: {
        color: '#95929c',
        fontSize: '11',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisPointer: {
          type: 'shadow'
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: true,

          fontSize: 10
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'KWh',
        splitLine: {
          show: false
        },
        axisLabel: {
          fontSize: '11'
        }
      },
      {
        type: 'value',
        name: '%',
        axisLabel: {
          fontSize: '11'
        },
        splitLine: {
          show: false
        },
      }
    ],
    series: yData
  }
}

// {
//   name: 'Evaporation',
//   type: 'bar',
//   tooltip: {
//     valueFormatter: function (value) {
//       return value + ' ml';
//     }
//   },
//   data: [
//     2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
//   ]
// }