<template>
  <div>
    <!-- 历史数据 -->
    <div class="row-box">
      <div class="content-box">
        <div class="title-box">
          <span>历史数据</span>
          <div class="up-down" @click="changeFlag(8)">
            {{ getInclude(8) ? "展开" : "收起"
            }}<a-icon type="up" :class="getInclude(8) ? 'roteIcon' : ''" />
          </div>
        </div>
        <div :class="getInclude(8) ? 'pack-up' : 'updown-box'">
          <div class="power-msg flex-end">
            <div class="right-content">
              <template v-if="templateActive != 5">
                <div class="time-more">
                  <div
                    v-for="t in timeTypeList"
                    :class="t.value == activeTime ? 'active-time' : ''"
                    @click="changeTimeType(t.value)"
                  >
                    {{ t.label }}
                  </div>
                </div>
                <!-- <div class="btn-operation ml-16">参数选择</div> -->
                <div class="btn-operation ml-16" @click="exportReport">
                  导出报表
                </div>

                <div class="time-picker">
                  <!-- <a-icon type="left" @click="dealTime(-1)" /> -->
                  <a-date-picker
                    class="picker"
                    :allowClear="false"
                    v-model="historyTime"
                    v-if="activeTime == 1"
                  />
                  <a-week-picker
                    :allowClear="false"
                    v-model="historyTime"
                    v-if="activeTime == 2"
                  />
                  <a-month-picker
                    :allowClear="false"
                    v-model="historyTime"
                    v-if="activeTime == 3"
                  />
                  <a-date-picker
                    class="picker"
                    :allowClear="false"
                    v-model="historyTime"
                    format="YYYY"
                    mode="year"
                    :open="orderOpen"
                    @openChange="openOrder"
                    @panelChange="panelOrder"
                    v-if="activeTime == 4"
                  />

                  <!-- <a-icon type="right" @click="dealTime(1)" /> -->
                </div>
              </template>
              <div class="time-picker" v-else>
                <a-date-picker
                  class="picker"
                  :allowClear="false"
                  v-model="rateTime"
                />
              </div>
            </div>
          </div>
          <div class="sys-template" v-if="activeTime < 3">
            <span>系统模板：</span>
            <div
              class="btn-operation ml-16 template-style"
              :class="tem.value == templateActive ? 'choose-template' : ''"
              v-for="tem in templateList"
              @click="changeTemplate(tem.value)"
            >
              {{ tem.label }}
            </div>
            <!-- <span class="how-tit">如何创建我的模板？</span> -->
          </div>
          <template v-if="templateActive != 5">
            <div id="historyE"></div>
          </template>

          <div v-else class="power-list">
            <a-empty v-if="allPowerData.length == 0"></a-empty>
            <div class="list-box" v-else>
              <div class="title-table">
                <div class="tit">时间</div>
                <div class="tit">总直流功率(KW)</div>
                <div class="tit">总有功功率(KW)</div>
                <div class="tit">发电量</div>
              </div>
              <div class="all-power">
                <div class="one-power" v-for="row in allPowerData">
                  <div class="one-content" v-for="column in row">
                    {{ column }}
                  </div>
                </div>
                <!-- <div class="scroll-box">
                 
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { lineEcharts } from "../config/historyEcharts";
import { powerHistoryEchart } from "../../homeData/index";
import { dcEcharts, dcAorVEcharts } from "../config/dcEcharts";
import moment from "moment";
import {
  queryInverterDetails,
  deviceHistoryData,
  dcHistoryData,
  historyDownload,
  dcPowerList,
} from "@/api/device";
import { exportDown } from "@/utils/index";
export default {
  data() {
    return {
      // 展开收起
      packUpFlag: [],

      // 模板列表
      templateList: [
        {
          value: "1",
          label: "交流分析",
        },
        {
          value: "2",
          label: "直流电流对比",
        },
        {
          value: "3",
          label: "直流电压对比",
        },
        {
          value: "4",
          label: "直流电流和电压对比",
        },
        {
          value: "5",
          label: "实时功率",
        },
      ],

      //历史数据查询时间
      historyTime: moment(),
      timeTypeList: [
        {
          value: "1",
          label: "日",
        },
        {
          value: "2",
          label: "周",
        },
        {
          value: "3",
          label: "月",
        },
        {
          value: "4",
          label: "年",
        },
        // {
        //   value: "5",
        //   label: "总",
        // },
      ],
      activeTime: "1",
      orderOpen: false,
      allTime: [moment("2015"), moment("2023")],
      detailList: {},
      chartInstance: null,
      templateActive: "1",
      allPowerData: [],
      rateTime: moment(),
    };
  },
  props: {
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getInclude(index) {
      return this.packUpFlag.includes(index);
    },
    changeFlag(index) {
      let findIndex = this.packUpFlag.findIndex((item) => {
        return item == index;
      });
      if (findIndex == -1) {
        this.packUpFlag.push(index);
      } else {
        this.packUpFlag.splice(findIndex, 1);
      }
    },
    initEcharts(data) {
      // const comparebox = document.getElementById("historyE");
      // const compareEchart = this.echarts.init(comparebox);
      this.instance();
      this.chartInstance.setOption(lineEcharts(data.xData, data.seriesData));
      setTimeout(() => {
        this.chartInstance.resize();
      }, 0);
    },
    initBarEcharts(data) {
      this.instance();
      this.chartInstance.setOption(powerHistoryEchart(data, "kWh", "发电量"));
      setTimeout(() => {
        this.chartInstance.resize();
      }, 0);
    },
    instance() {
      const comparebox = document.getElementById("historyE");
      if (!this.chartInstance) {
        this.chartInstance = this.echarts.init(comparebox);
      }
    },
    changeTimeType(value) {
      this.activeTime = value;
      if (this.activeTime == 3 || this.activeTime == 4) {
        this.templateActive = 1;
      }
      this.getHistory();
    },

    getHistory() {
      if (this.templateActive == 1) {
        deviceHistoryData({
          id: this.currentData.id,
          timeType: this.activeTime,
          ...this.getTime(),
        })
          .then((res) => {
            if (this.activeTime == 2 || this.activeTime == 1) {
              let dealData = this.dealEchartsData(res.data);
              this.initEcharts(dealData);
            } else {
              console.log(res);
              let dealData = this.dealMonthYear(res.data);
              this.initBarEcharts(dealData);
            }
          })
          .catch((err) => {});
      } else if (this.templateActive == 5) {
        this.getPowerData();
      } else {
        dcHistoryData({
          id: this.currentData.id,
          timeType: this.activeTime,
          ...this.getTime(),
        }).then((res) => {
          this.dealDcData(res.data);
        });
      }
    },
    getPowerData() {
      dcPowerList({
        id: this.currentData.id,
        createTime: moment(this.rateTime).format("YYYY-MM-DD 00:00:00"),
      }).then((res) => {
        this.allPowerData = res.data.map((item) => {
          return [
            item.createTime || "--",
            item.allDCPower || 0,
            item.allActivePower || 0,
            item.dayPower || 0,
          ];
        });
      });
    },
    getTime() {
      let startTime, endTime;
      console.log(this.historyTime, this.timeType);
      if (this.activeTime == 1) {
        startTime = moment(this.historyTime).format("YYYY-MM-DD 00:00:00");
        endTime = moment(this.historyTime).format("YYYY-MM-DD 00:00:00");
      } else if (this.activeTime == 2) {
        startTime = moment(this.historyTime)
          .startOf("week")
          .format("YYYY-MM-DD 00:00:00");
        endTime = moment(this.historyTime)
          .endOf("week")
          .format("YYYY-MM-DD 00:00:00");
        console.log("week");
      } else if (this.activeTime == 3) {
        startTime = moment(this.historyTime)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        endTime = moment(this.historyTime)
          .endOf("month")
          .format("YYYY-MM-DD 00:00:00");
      } else {
        startTime = moment(this.historyTime)
          .startOf("year")
          .format("YYYY-MM-DD 00:00:00");
        endTime = moment(this.historyTime)
          .endOf("year")
          .format("YYYY-MM-DD 00:00:00");
      }
      return {
        startTime,
        endTime,
      };
    },
    dealEchartsData(data) {
      if (Object.keys(data).length != 0) {
        let startTime = this.historyTime;
        let minTime, maxTime;
        if (this.activeTime == 1) {
          minTime = moment(startTime).startOf("day").valueOf();
          maxTime = moment(startTime).endOf("day").valueOf();
        } else {
          minTime = moment(startTime).startOf("week").valueOf();
          maxTime = moment(startTime).endOf("week").valueOf();
        }

        //交流RUA
        let Rvoltage = [],
          Rcurrent = [],
          Rrate = [];
        // 交流SVB
        let Svoltage = [],
          Scurrent = [];
        // 交流TWC
        let Tvoltage = [],
          Tcurrent = [];

        let activePowerData = data.allActivePower;
        delete data.allActivePower;
        console.log(data);
        //交流总功率
        let activePower = [];
        for (let key in data) {
          data[key].forEach((item) => {
            if (item.title == "R") {
              Rvoltage.push([item.createTime, item.voltage]);
              Rcurrent.push([item.createTime, item.current]);
              Rrate.push([item.createTime, item.rate]);
            } else if (item.title == "S") {
              Svoltage.push([item.createTime, item.voltage]);
              Scurrent.push([item.createTime, item.current]);
            } else if (item.title == "T") {
              Tvoltage.push([item.createTime, item.voltage]);
              Tcurrent.push([item.createTime, item.current]);
            }
          });
        }
        activePowerData.forEach((item) => {
          activePower.push([item.createTime, item.allActivePower]);
        });
        Rvoltage.sort((a, b) => {
          return moment(a[0]).valueOf() - moment(b[0]).valueOf();
        });
        console.log(Rvoltage, "--------Rvoltage----", activePower);
        minTime = moment(Rvoltage[0][0]).valueOf();
        let index = Rvoltage.length - 1;
        maxTime = moment(Rvoltage[index][0]).valueOf();
        return {
          xData: {
            min: minTime,
            max: maxTime,
          },
          seriesData: [
            Rvoltage,
            Svoltage.sort((a, b) => {
              return moment(a[0]).valueOf() - moment(b[0]).valueOf();
            }),
            Tvoltage.sort((a, b) => {
              return moment(a[0]).valueOf() - moment(b[0]).valueOf();
            }),
            Rcurrent.sort((a, b) => {
              return moment(a[0]).valueOf() - moment(b[0]).valueOf();
            }),
            Scurrent.sort((a, b) => {
              return moment(a[0]).valueOf() - moment(b[0]).valueOf();
            }),
            Tcurrent.sort((a, b) => {
              return moment(a[0]).valueOf() - moment(b[0]).valueOf();
            }),
            Rrate.sort((a, b) => {
              return moment(a[0]).valueOf() - moment(b[0]).valueOf();
            }),
            activePower.sort((a, b) => {
              return moment(a[0]).valueOf() - moment(b[0]).valueOf();
            }),
          ],
        };
      }
    },
    dealMonthYear(data) {
      let xData = [],
        yData = [];
      let arrData = [];

      for (let k in data) {
        arrData.push({ time: k, value: data[k] });
      }
      arrData.sort((a, b) => {
        return moment(a.time).valueOf() - moment(b.time).valueOf();
      });
      arrData.map((item) => {
        xData.push(moment(item.time).format("YYYY-MM-DD"));
        yData.push(item.value);
      });
      return {
        xData,
        yData,
      };
    },
    changeTemplate(index) {
      if (index == this.templateActive) {
        return;
      }
      this.templateActive = index;
      if (this.templateActive == 5 && this.chartInstance) {
        this.chartInstance.dispose();
        this.chartInstance = null;
      }
      this.$nextTick(() => {
        this.getHistory();
      });
    },
    dealDcData(data) {
      if (Object.keys(data).length != 0) {
        let minTime, maxTime;
        if (this.activeTime == 1) {
          minTime = moment(this.historyTime).startOf("day").valueOf();
          maxTime = moment(this.historyTime).endOf("day").valueOf();
        } else {
          minTime = moment(this.historyTime).startOf("week").valueOf();
          maxTime = moment(this.historyTime).endOf("week").valueOf();
        }
        let legend = [];
        let yData = [];
        let flag = 0;
        let legendText =
          this.templateActive == 2
            ? "直流电流"
            : this.templateActive == 3
            ? "直流电压"
            : ["直流电流", "直流电压"];
        for (let k in data) {
          data[k].forEach((dc) => {
            if (flag == 0) {
              if (this.templateActive == 4) {
                legend.push(
                  ...[legendText[0] + dc.title, legendText[1] + dc.title]
                );
                yData.push(
                  ...[
                    {
                      name: legendText[0] + dc.title,
                      type: "line",
                      data: [],
                      // areaStyle: {},
                      symbol: "none",
                      smooth: true,
                      yAxisIndex: 0,
                    },
                    {
                      name: legendText[1] + dc.title,
                      type: "line",
                      yAxisIndex: 1,
                      data: [],
                      smooth: true,
                      // areaStyle: {},
                      symbol: "none",
                    },
                  ]
                );
              } else {
                legend.push(legendText + dc.title);
                yData.push({
                  name: legendText + dc.title,
                  type: "line",
                  data: [],
                  smooth: true,
                  // areaStyle: {},
                  symbol: "none",
                });
              }
            }

            yData.forEach((y) => {
              if (this.templateActive == 2) {
                // let dataTime = data[k].map((item) => {
                //   return [item.createTime, item.current];
                // });
                if (y.name == legendText + dc.title) {
                  y.data.push([dc.createTime, dc.current]);
                }
              } else if (this.templateActive == 3) {
                if (y.name == legendText + dc.title) {
                  y.data.push([dc.createTime, dc.voltage]);
                }
              } else {
                if (y.name == legendText[0] + dc.title) {
                  y.data.push([dc.createTime, dc.voltage]);
                }
                if (y.name == legendText[1] + dc.title) {
                  y.data.push([dc.createTime, dc.current]);
                }
              }
            });
          });
          flag++;
        }

        // const comparebox = document.getElementById("historyE");
        // const compareEchart = this.echarts.init(comparebox);

        // compareEchart.dispose();
        // compareEchart.clear();
        this.instance();
        this.chartInstance.clear();
        yData.forEach((item) => {
          item.data.sort((a, b) => {
            return moment(a[0]).valueOf() - moment(b[0]).valueOf();
          });
        });
        minTime = moment(yData[0].data[0][0]).valueOf();
        let index = yData[0].data.length - 1;
        maxTime = moment(yData[0].data[index][0]).valueOf();
        console.log(yData[0].data[index][0], "------legend-----", yData[0]);
        if (this.templateActive == 4) {
          this.chartInstance.setOption(
            dcAorVEcharts({ min: minTime, max: maxTime }, legend, yData)
          );
        } else {
          this.chartInstance.setOption(
            dcEcharts({ min: minTime, max: maxTime }, legend, yData)
          );
        }

        console.log(dcEcharts({ min: minTime, max: maxTime }, legend, yData));
        setTimeout(() => {
          this.chartInstance.resize();
        }, 0);
      }
    },
    exportReport() {
      let startTime, endTime;
      if (this.activeTime == 1) {
        startTime = moment().startOf("day").format("YYYY-MM-DD 00:00:00");
        endTime = moment().endOf("day").format("YYYY-MM-DD 00:00:00");
      } else if (this.activeTime == 2) {
        startTime = moment().startOf("week").format("YYYY-MM-DD 00:00:00");
        endTime = moment().endOf("week").format("YYYY-MM-DD 00:00:00");
      } else if (this.activeTime == 3) {
        startTime = moment().startOf("month").format("YYYY-MM-DD 00:00:00");
        endTime = moment().endOf("month").format("YYYY-MM-DD 00:00:00");
      } else {
        startTime = moment().startOf("year").format("YYYY-MM-DD 00:00:00");
        endTime = moment().endOf("year").format("YYYY-MM-DD 00:00:00");
      }

      let requestData = {
        id: this.currentData.id,
        timeType: this.activeTime,
        startTime,
        endTime,
      };
      historyDownload(requestData).then((res) => {
        exportDown(res, "历史数据");
        this.$message.success("导出数据成功");
      });
    },
  },
  watch: {
    historyTime(newv) {
      this.getHistory();
      console.log(newv);
    },
    rateTime(newv) {
      this.getPowerData();
    },
  },
  mounted() {
    this.getHistory();
  },
};
</script>
<style lang="less" scoped>
.row-box {
  position: relative;
  border: 1px solid rgba(37, 43, 58, 0.1);
  border-radius: 4px;
  background: #fff;
  padding: 16px 0;
  box-sizing: border-box;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;
  .content-box {
    padding: 0 16px;
    .btn-operation {
      padding: 0 14px;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      font-size: 12px;
      height: 22px;
      line-height: 20px;
      color: #00000073;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        border-color: #048fff;
        color: #048fff;
      }
    }

    // 标题
    .title-box {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
        color: #252b3a;
      }
      .up-down {
        font-size: 12px;
        color: #048fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        i {
          font-size: 16px;
        }
        .roteIcon {
          transform: rotate(180deg);
        }
      }
    }
    .content-msg {
      .grid-box {
        display: grid;

        margin-top: 16px;
        .one-msg {
          font-size: 12px;
          color: #00000073;
          .one-detail {
            color: #515561;
          }
        }
      }
    }

    .updown-box {
      overflow: hidden;
      max-height: 3000px;
    }
    .pack-up {
      overflow: hidden;
      height: 0;
    }

    .power-msg {
      box-sizing: border-box;
      padding: 0 20px;
      margin-top: 20px;
      .grid-box {
        display: grid;
        margin-top: 16px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 16px;
        .one-msg {
          font-size: 12px;
          color: #00000073;
          .one-detail {
            color: #515561;
          }
        }
      }
      .right-content {
        display: flex;
        align-items: center;
        .time-more {
          height: 24px;
          display: flex;
          div {
            border: 1px solid #d9d9d9;
            padding: 0 8px;
            font-size: 12px;
            height: 100%;
            line-height: 22px;
            cursor: pointer;
            &:hover {
              color: #048fff;
              background-color: #048fff1a;
              border-color: #048fff;
            }
          }
          .active-time {
            color: #048fff;
            background-color: #048fff1a;
            border-color: #048fff;
          }
        }
        .time-picker {
          margin-left: 8px;
          cursor: pointer;
          &:hover {
            color: #048fff;
          }
          .picker {
            margin: 0 6px;
          }
          .ant-calendar-picker {
            /deep/.ant-calendar-picker-input {
              width: 128px;
              height: 30px;
              font-size: 12px;
            }
            // height: 24px;
          }
          .rang-picker {
            /deep/.ant-calendar-picker-input {
              width: 148px;
            }
          }
        }
      }
    }
    .sys-template {
      height: 48px;
      margin-top: 8px;
      background-color: #f8f8f8;
      padding: 0 16px;
      color: #515561;
      display: flex;
      font-size: 12px;
      align-items: center;
      .how-tit {
        color: #048fff;
        cursor: pointer;
        margin-left: 24px;
      }
      .choose-template {
        color: #048fff;
        border-color: #048fff;
        background-color: #048fff1a;
      }
      .template-style {
        &:hover {
          color: #048fff;
          border-color: #048fff;
          background-color: #048fff1a;
        }
      }
    }
    #historyE {
      height: 384px;
      width: 100%;
    }
    .power-list {
      height: 450px;
      width: 100%;
      overflow-y: auto;
      // overflow: hidden;
      .list-box {
        width: 70%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        // overflow-y: auto;

        .title-table {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          height: 50px;
          .tit {
            padding: 10px 20px;
            text-align: center;
            font-size: 14px;
            color: #000;
            font-weight: 600;
          }
        }
        .all-power {
          flex-grow: 1;
          max-height: 400px;
          // over
          overflow-y: auto;
          // // overflow: hidden;
          .one-power {
            display: grid;
            overflow-y: auto;
            grid-template-columns: repeat(4, 1fr);
            .one-content {
              padding: 10px 20px;
              text-align: center;
              font-size: 12px;
              color: #515151;
              font-weight: 400;
            }
          }
          .scroll-box {
            // height: 100%;
            // overflow-y: auto;
          }
        }
      }
    }
    .flex-end {
      display: flex;
      justify-content: flex-end;
    }
    .flex-box {
      display: flex;
      align-items: center;
      div {
        flex: 1;
      }
    }
  }
}
</style>