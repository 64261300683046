<template>
    <a-drawer
            placement="top"
            :closable="false"
            :visible="topDrawer"
            height="100%"
            wrapClassName="add-power"
            @close="closeTopDrawer"
            :destroyOnClose="true"
            :headerStyle="{
                background: '#f0f1f5',
                padding: '0 0.2rem 0 0.3rem',
            }"
            :bodyStyle="{
                padding: '0.3rem 0 0 0',
            }"
    >
        <template #title>
            <div class="head-box">
                <span>编辑设备信息</span>
                <div class="head-btn">
                    <a-button @click="closeTopDrawer">取消</a-button>
                    <a-button type="primary" class="save-btn" @click="saveStation">保存</a-button>
                </div>
            </div>
        </template>
        <div class="content-box">
            <div class="drawer-box">
                <a-form-model :model="form" ref="ruleForm">
                    <a-form-model-item
                            label="设备名称"
                            required
                            prop="deviceName"
                            :rules="{
                                  required: 'true',
                                  message: '请填写设备名称',
                                  trigger: 'blur',}"
                    >
                        <a-input v-model="form.deviceName" class="input-class"/>
                    </a-form-model-item>
                    <a-form-model-item
                            label="安装位置"
                            required
                            prop="childSystem"
                            :rules="{
                              required: 'true',
                              message: '请填写安装位置',
                              trigger: 'blur',
                            }">
                        <a-input v-model="form.childSystem" class="input-class"/>
                    </a-form-model-item>
                    <a-form-model-item
                            label="矩阵（设备编号）"
                            required
                            prop="matrix"
                            :rules="{
                              required: 'true',
                              message: '请填写矩阵（设备编号）',
                              trigger: 'blur',
                            }">
                        <a-input v-model="form.matrix" class="input-class"/>
                    </a-form-model-item>
                    <div class="row-title">
                        组件容量(KWh):
                        {{ count ? count : "--" }}(组件容量将根据以下填写内容自动计算)
                    </div>
                    <div class="grid-box">
                        <div class="left-box">
                            <div class="head-title">
                                <div v-for="item in headTitle">{{ item }}</div>
                            </div>
                            <div class="input-box" v-for="(i, index) in form.leftGroup">
                                <div>
                                    <a-checkbox :checked="i.checked" @change="(e) => { changeCheckbox(e, i) }">
                                        {{ i.checkName }}
                                    </a-checkbox>
                                </div>
                                <div>
                                    <a-form-model-item
                                            :prop="'leftGroup.' + index + '.power'"
                                            :rules="i.checked
                                                        ? {
                                                            validator: checkPower,
                                                            trigger: 'blur',
                                                          }
                                                        : null
                                                    ">
                                        <a-input
                                                style="width: 80%"
                                                class="both-input"
                                                v-model="i.power"
                                                :disabled="!i.checked"
                                                @change="getCount"
                                        ></a-input>
                                    </a-form-model-item>
                                </div>
                                <div>
                                    <a-form-model-item
                                            :prop="'leftGroup.' + index + '.num'"
                                            :rules="
                      i.checked
                        ? {
                            validator: checkNum,
                            trigger: 'blur',
                          }
                        : null
                    "
                                    >
                                        <a-input
                                                style="width: 80%"
                                                class="both-input"
                                                v-model="i.num"
                                                :disabled="!i.checked"
                                                @change="getCount"
                                        ></a-input>
                                    </a-form-model-item>
                                </div>
                                <a-form-model-item>
                                    <div>{{ total(i) ? total(i) : "--" }}</div>
                                </a-form-model-item>
                            </div>
                        </div>
                        <div class="right-box">
                            <div class="head-title">
                                <div v-for="item in headTitle">{{ item }}</div>
                            </div>
                            <div class="input-box" v-for="(i, index) in form.rightGroup">
                                <div>
                                    <a-checkbox
                                            :checked="i.checked"
                                            @change="
                      (e) => {
                        changeCheckbox(e, i);
                      }
                    "
                                    >
                                        {{ i.checkName }}
                                    </a-checkbox>
                                </div>
                                <div>
                                    <a-form-model-item
                                            :prop="'rightGroup.' + index + '.power'"
                                            :rules="
                      i.checked
                        ? {
                            validator: checkPower,
                            trigger: 'blur',
                          }
                        : null
                    "
                                    >
                                        <a-input
                                                style="width: 80%"
                                                class="both-input"
                                                v-model="i.power"
                                                :disabled="!i.checked"
                                                @change="getCount"
                                        ></a-input>
                                    </a-form-model-item>
                                </div>
                                <div>
                                    <a-form-model-item
                                            :prop="'rightGroup.' + index + '.num'"
                                            :rules="
                      i.checked
                        ? {
                            validator: checkNum,
                            trigger: 'blur',
                          }
                        : null
                    "
                                    >
                                        <a-input
                                                style="width: 80%"
                                                class="both-input"
                                                v-model="i.num"
                                                :disabled="!i.checked"
                                                @change="getCount"
                                        ></a-input>
                                    </a-form-model-item>
                                </div>
                                <a-form-model-item>
                                    <div>{{ total(i) ? total(i) : "--" }}</div>
                                </a-form-model-item>
                            </div>
                        </div>
                    </div>
                </a-form-model>
            </div>
        </div>
    </a-drawer>
</template>
<script>
    import {
        capacitySaveSingle,
        capacityQueryList,
        querySingleName,
    } from "@/api/device.js";

    export default {
        data() {
            let checkPower = (rule, value, callback) => {
                console.log(value);
                if (Number(value) < 0.01 || Number(value) > 100000000) {
                    callback(new Error("0.01~100000000"));
                } else {
                    callback();
                }
            };

            let checkNum = (rule, value, callback) => {
                console.log(value);
                if (Number(value) < 1 || Number(value) > 100000000) {
                    callback(new Error("1~100000000"));
                } else {
                    callback();
                }
            };
            return {
                form: {
                    deviceName: "",
                    childSystem: "",
                    matrix: '',
                    leftGroup: [],
                    rightGroup: [],
                },
                headTitle: ["实际启用组串", "组件额定功率(W)", "组件数量", "合计(W)"],
                checkPower,
                checkNum,
                count: 0,
                visible: true,
            };
        },
        props: {
            currentData: {
                type: Object,
                default: () => {
                },
            },
            topDrawer: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            closeTopDrawer() {
                this.$emit("closeEditDrawer");
            },
            saveStation() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let allArr = [...this.form.leftGroup, ...this.form.rightGroup];
                        let grop = allArr
                            .filter((item) => {
                                return item.checked;
                            })
                            .map((item) => {
                                return {
                                    title: item.checkName,
                                    ratedPower: item.power,
                                    number: item.num,
                                    amountTile: item.total,
                                };
                            });
                        capacitySaveSingle({
                            deviceTitle: this.form.deviceName,
                            childSystem: this.form.childSystem,
                            matrix: this.form.matrix,
                            inverterSn: this.currentData.deviceSn,
                            capacity: this.count,
                            inverterComponentCapacityEntities: grop,
                        }).then((res) => {
                            if (res.code == "000000") {
                                this.$emit("reloadTable");
                            }
                            console.log(res);
                        });
                    } else {
                        return false;
                    }
                });
            },
            total(i) {
                let v = Number(i.power) * Number(i.num);
                i.total = v;
                return v;
            },
            changeCheckbox(e, i) {
                i.checked = !i.checked;
            },
            getCount() {
                let num = 0;
                this.form.leftGroup.forEach((item) => {
                    let t = item.num * item.power;
                    num += t;
                });
                this.form.rightGroup.forEach((item) => {
                    let t = item.num * item.power;
                    num += t;
                });
                this.count = num / 1000;
            },
        },
        watch: {},
        async mounted() {
            // console.log("----------", this.currentData);
            for (let k = 1; k < 19; k++) {
                let obj = {
                    checked: false,
                    checkName: "PV" + k,
                    power: "",
                    num: "",
                    total: "",
                };
                if (k < 10) {
                    this.form.leftGroup.push(obj);
                } else {
                    this.form.rightGroup.push(obj);
                }
            }
            const nameRes = await querySingleName({
                deviceSn: this.currentData.deviceSn,
            });
            this.form.deviceName = nameRes.data.deviceTitle;
            this.form.childSystem = nameRes.data.childSystem
            this.form.matrix = nameRes.data.matrix
            capacityQueryList({
                inverterSn: this.currentData.deviceSn,
            }).then((res) => {
                this.form.leftGroup.map((item) => {
                    res.data.forEach((item2) => {
                        if (item2.title == item.checkName) {
                            item.checked = true;
                            item.power = item2.ratedPower;
                            item.num = item2.number;
                        }
                    });
                });
                this.form.rightGroup.map((item) => {
                    res.data.forEach((item2) => {
                        if (item2.title == item.checkName) {
                            item.checked = true;
                            item.power = item2.ratedPower;
                            item.num = item2.number;
                        }
                    });
                });
                this.getCount();
            });
        },
        computed: {},
    };
</script>
<style lang="less" scoped>
    .add-power {
        .head-box {
            width: 100%;
            height: 64px;
            font-size: 20px;
            color: #252b3a;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .head-btn {
                /deep/ .ant-btn {
                    font-size: 12px;
                }

                .save-btn {
                    margin-left: 8px;
                }
            }
        }

        .content-box {
            padding-top: 24px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            .drawer-box {
                width: 80%;

                .input-class {
                    width: 45%;
                }

                .row-title {
                    font-size: 19px;
                }

                .grid-box {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    padding-top: 16px;

                    .head-title {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        font-size: 12px;
                    }

                    .input-box {
                        display: grid;
                        height: 60px;

                        /deep/ .ant-form-item {
                            margin-bottom: 10px;
                            font-size: 12px;
                        }

                        /deep/ .ant-form-explain {
                            font-size: 12px;
                            line-height: 0.4;
                        }

                        //   height: 40px;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        font-size: 12px;
                        padding-top: 8px;

                        .both-input {
                            width: 80%;
                            height: 32px;
                        }
                    }
                }
            }
        }
    }
</style>
