<template>
    <div class="max-container">
        <!-- 基础信息 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>基础信息</span>
                    <div class="up-down" @click="changeFlag(0)">
                        {{ getInclude(0) ? "展开" : "收起" }}
                        <a-icon type="up" :class="getInclude(0) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(0) ? 'pack-up' : 'updown-box'">
                    <div class="content-msg">
                        <div class="grid-box">
                            <div class="one-msg">
                                <span>{{ baseInfo.title }}</span>
                                <span class="one-detail">{{ baseInfo.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 发电 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>发电</span>
                    <div class="up-down" @click="changeFlag(1)">
                        {{ getInclude(1) ? "展开" : "收起" }}
                        <a-icon type="up" :class="getInclude(1) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(1) ? 'pack-up' : 'updown-box'">
                    <!-- 发电 -->
                    <div class="content-msg flex-box mt-16">
                        <div class="table-box">
                            <div class="table-title">
                                <div>直流</div>
                                <div>电压</div>
                                <div>电流</div>
                                <div>功率</div>
                            </div>
                            <div class="table-content" v-for="direct in directList">
                                <div>{{ direct.title }}</div>
                                <div>{{ direct.voltage }}V</div>
                                <div>{{ direct.current }}A</div>
                                <div>{{ direct.rate }}W</div>
                            </div>
                        </div>
                        <div class="center-box">
                            <div class="progress-box">
                                <div class="square-box">
                                    <div class="square-inner">
                                        <div class="square-ctn">
                                            <span class="small">DC/AC</span>
                                            <span class="line"></span>
                                            <span class="bolang">～</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-box">
                            <div class="table-title">
                                <div>交流</div>
                                <div>电压</div>
                                <div>电流</div>
                                <div>频率</div>
                            </div>
                            <div class="table-content" v-for="communication in communicationList">
                                <div>{{ communication.title }}</div>
                                <div>{{ communication.voltage }}V</div>
                                <div>{{ communication.current }}A</div>
                                <div>{{ communication.rate }}Hz</div>
                            </div>
                        </div>
                    </div>
                    <div class="power-msg">
                        <div class="grid-box grid-power">
                            <div class="one-msg" v-for="item in powerList">
                                <span>{{ item.title }}</span>
                                <span class="one-detail">{{ item.value }}{{ item.unit ? item.unit : "" }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 历史数据 -->
        <div class="row-box">
            <div class="content-box">
                <div class="title-box">
                    <span>历史数据</span>
                    <div class="up-down" @click="changeFlag(2)">
                        {{ getInclude(2) ? "展开" : "收起" }}
                        <a-icon type="up" :class="getInclude(2) ? 'roteIcon' : ''"/>
                    </div>
                </div>
                <div :class="getInclude(2) ? 'pack-up' : 'updown-box'">
                    <div class="power-msg flex-end">
                        <div class="right-content">
                            <div class="raleDataList" @click="raleDataList">实时发电</div>
                            <div class="time-picker" v-if="activeTime == 1 || activeTime == 4">
                                <a-date-picker class="picker" :allowClear="false" v-model="createTime" @change="getCreateTime" />
                            </div>
                            <div class="time-more">
                                <div v-for="t in timeTypeList" :class="t.value == activeTime ? 'active-time' : ''" @click="changeTimeType(t.value)">
                                    {{ t.label }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="historyMonitoring" v-if="activeTime == 2 || activeTime == 3"></div>
	                <div class="pv-boxs" v-for="(monitorBox, key) in monitorBoardList" :key="key" v-if="activeTime == 1">
                        <div class="pv-box" :class="monitor.voltage == 0 ? 'grey':'on'" v-for="monitor in monitorBox" :key="monitor.id">
			                <a-popover :overlayStyle="{ width: '150px' }" overlayClassName="pv-popover">
				                <template slot="content">
					                <div>
						                <div class="title">组件({{monitor.monitorSn}})</div>
						                <div class="splitLine"></div>
						                <div>
							                <div class="flex-aligns-justifyb mb-10">
								                <div class="flex-sub1">电压：{{monitor.voltage}}V</div>
							                </div>
							                <div class="flex-aligns-justifyb mb-10">
                                                <div class="flex-sub1">电流：{{monitor.current}}A</div>
							                </div>
							                <div class="flex-aligns-justifyb mb-10">
                                                <div class="flex-sub1">功率：{{monitor.powerRate}}W</div>
							                </div>
                                            <div>发电量：{{monitor.amount}}KWh</div>
						                </div>
					                </div>
				                </template>
				                <div class="pv-numb flex-center">
					                <div>{{monitor.powerRate}}</div>
					                <div>W</div>
				                </div>
			                </a-popover>
		                </div>
	                </div>
                    <!--实时发电列表-->
                    <div v-if="activeTime==4">
                        <a-table :dataSource="toDayList" :columns="toDayColumns" :loading="toDayLoading" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import {monitoringBarEcharts} from "../config/historyEcharts";
    import {queryMonitorDetails, monitorHistoryData, queryRealTimeList} from "@/api/device";
    import {queryToDayList} from "../../../api/device";

    export default {
        data() {
            return {
                // 基础信息
                baseInfo: {
                    title: "序列号：",
                    value: "900000007-1",
                    prop: "deviceSn",
                },
                // 展开收起
                packUpFlag: [],
                // 发电
                directList: [], //直流数据
                communicationList: [], //交流数据
                powerList: [
                    {
                        title: "直流输入总功率：",
                        value: "0",
                        prop: "allDCPower",
                        unit: "W",
                    },
                    {
                        title: "累计发电量：",
                        value: "0",
                        prop: "allPower",
                        unit: "MWh",
                    },
                    {
                        title: "当日发电量：",
                        value: "0",
                        prop: "dayPower",
                        unit: "kWh",
                    },
                ],
                //历史数据查询时间
                historyTime: moment(),
                timeTypeList: [
                    {
                        value: "1",
                        label: "日",
                    },
                    {
                        value: "2",
                        label: "月",
                    },
                    {
                        value: "3",
                        label: "年",
                    },
                ],
                activeTime: "1",
                orderOpen: false,
                allTime: [moment("2015"), moment("2023")],
                detailList: {},
                monitorBoardList: [],// 监控器底部实时发电各组板子数据
                toDayColumns: [
                    { title: '发电量（KWh）',dataIndex: 'amount', key: 'amount' },
                    { title: '电压', dataIndex: 'voltage', key: 'voltage' },
                    { title: '电流', dataIndex: 'current', key: 'current' },
                    { title: '功率', dataIndex: 'powerRate', key: 'powerRate' },
                    { title: '时间', dataIndex: 'createTime', key: 'createTime' },
                ],// 监控器底部当天实时发电列表
                toDayList: [], // 监控器底部当天实时发电数据列表
                toDayLoading: false,
                createTime: moment(), // 查询日期
            };
        },
        props: {
            currentData: {
                type: Object,
                default: () => {
                },
            },
        },
        methods: {
            getInclude(index) {
                return this.packUpFlag.includes(index);
            },
            changeFlag(index) {
                let findIndex = this.packUpFlag.findIndex((item) => {
                    return item == index;
                });
                if (findIndex == -1) {
                    this.packUpFlag.push(index);
                } else {
                    this.packUpFlag.splice(findIndex, 1);
                }
            },
            changeTimeType(value) {
                this.activeTime = value;
                this.getHistoryData();
            },
            openOrder(val) {
                if (val) {
                    this.orderOpen = true;
                } else {
                    this.orderOpen = false;
                }
            },
            panelOrder(val) {
                this.historyTime = moment(val);
                this.orderOpen = false;
            },
            initEcharts(data) {
                const comparebox = document.getElementById("historyMonitoring");
                const compareEchart = this.echarts.init(comparebox, null, {
                    devicePixelRatio: window.devicePixelRatio,
                });
                compareEchart.setOption(monitoringBarEcharts(data));
                setTimeout(() => {
                    compareEchart.resize();
                }, 0);
            },
            getDetails() {
                queryMonitorDetails({id: this.currentData.id}).then((res) => {
                    this.detailList = res.data;
                    this.baseInfo.value = res.data.baseInfo.deviceSn;
                    this.directList.push({
                        voltage: res.data.voltage,
                        current: res.data.current,
                        rate: res.data.rate,
                        title: "PV1",
                    });
                    this.powerList.map((item) => {
                        for (let key in res.data.baseInfo) {
                            if (key == item.prop) {
                                item.value = res.data.baseInfo[key];
                            }
                        }
                    });
                }).catch((err) => {
                    console.log(err);
                });
            },
            getHistoryData() {
                if (this.activeTime == 2 || this.activeTime == 3) {
                    monitorHistoryData({
                        timeType: this.activeTime,
                        monitorSn: this.currentData.deviceSn,
                    }).then((res) => {
                        let xData = [],
                            yData = [];
                        for (let k in res.data) {
                            xData.push(k);
                            yData.push(res.data[k]);
                        }
                        xData.sort((a, b) => {
                            return moment(a).unix() - moment(b).unix()
                        })
                        this.initEcharts({xData, yData});
                    });
                }
            },
	        // 查询监控器实时发电数据
	        getRealTimeList(){
		        queryRealTimeList ({
			        timeType: this.activeTime,
			        monitorSn: this.currentData.deviceSn,
                    createTime: moment(this.createTime).format('YYYY-MM-DD 00:00:00')
		        }).then ( res => {
                    this.monitorBoardList = res.data
		        } )
	        },
            // 实时发电列表
            raleDataList(){
                this.activeTime = '4'
                this.toDayLoading = true
                queryToDayList({monitorSn: this.currentData.deviceSn,createTime: moment(this.createTime).format('YYYY-MM-DD 00:00:00')}).then(res=>{
                    this.toDayList = res.data
                    this.toDayLoading = false
                })
            },
            // 点击日期
            getCreateTime(val){
                // 点击日期，根据activeTime为1和4分别查询实时发电数据列表和实时发电板子数据
                if (this.activeTime == 1) {
                    queryRealTimeList ({
                        timeType: this.activeTime,
                        monitorSn: this.currentData.deviceSn,
                        createTime: moment(val).format("YYYY-MM-DD 00:00:00")
                    }).then ( res => {
                        this.monitorBoardList = res.data
                    } )
                }
                if (this.activeTime == 4) {
                    this.toDayLoading = true
                    queryToDayList({
                        monitorSn: this.currentData.deviceSn,
                        createTime: moment(this.createTime).format('YYYY-MM-DD 00:00:00')
                    }).then(res=>{
                        this.toDayList = res.data
                        this.toDayLoading = false
                    })
                }
            },
        },
        watch: {
            // detailList(newv) {
            // },
        },
        mounted() {
            this.getDetails();
            this.getHistoryData();
            this.getRealTimeList()
        },
    };
</script>
<style lang="less" scoped>
    .max-container {
        .row-box {
            position: relative;
            border: 1px solid rgba(37, 43, 58, 0.1);
            border-radius: 4px;
            background: #fff;
            padding: 16px 0;
            box-sizing: border-box;
            box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
            margin-bottom: 8px;

            .content-box {
                padding: 0 16px;

                // 标题
                .title-box {
                    display: flex;
                    justify-content: space-between;

                    span {
                        font-size: 16px;
                        color: #252b3a;
                    }

                    .up-down {
                        font-size: 12px;
                        color: #048fff;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        i {
                            font-size: 16px;
                        }

                        .roteIcon {
                            transform: rotate(180deg);
                        }
                    }
                }

                .content-msg {
                    .grid-box {
                        display: grid;

                        margin-top: 16px;

                        .one-msg {
                            font-size: 12px;
                            color: #00000073;

                            .one-detail {
                                color: #515561;
                            }
                        }
                    }

                    .grid-base {
                        grid-template-rows: 1fr 1fr;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-row-gap: 16px;
                    }

                    .grid-real {
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-row-gap: 16px;
                    }

                    .grid-version {
                        grid-template-columns: 1fr 1fr 1fr;
                    }

                    .table-box {
                        width: 100%;
                        display: grid;
                        grid-row-gap: 16px;
                        height: max-content;

                        .table-title,
                        .table-content {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            font-size: 12px;
                            color: #048fff;

                            div {
                                text-align: center;
                            }
                        }

                        .table-title {
                            div {
                                color: #95929c;
                            }
                        }
                    }

                    .center-box {
                        display: flex;
                        justify-content: center;

                        .progress-box {
                            width: 90%;
                            height: 10px;
                            position: relative;
                            background: #cde9ff;
                            border-radius: 4px;
                            @keyframes widthAdd {
                                from {
                                    width: 0;
                                }
                                to {
                                    width: 100%;
                                }
                            }

                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 0;
                                animation: widthAdd 4s linear infinite;
                                border-radius: 4px;
                                height: 100%;
                                background: #048fff;
                            }

                            .square-box {
                                width: 56px;
                                height: 56px;
                                padding: 5px;
                                box-sizing: border-box;
                                position: absolute;
                                left: 50%;
                                top: 0;
                                background: #fff;
                                transform: translateY(-40%);

                                .square-inner {
                                    width: 100%;
                                    height: 100%;
                                    background: #048fff;
                                    border-radius: 4px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: #fff;

                                    .square-ctn {
                                        display: flex;
                                        flex-direction: column;
                                        font-size: 12px;
                                        align-items: center;

                                        .small {
                                            transform: scale(0.8);
                                            font-weight: 700;
                                        }

                                        .line {
                                            width: 70%;
                                            height: 1px;
                                            background: #fff;
                                        }

                                        .bolang {
                                            font-size: 20px;
                                            line-height: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .power-msg {
                    box-sizing: border-box;
                    padding: 0 20px;
                    margin-top: 20px;

                    .grid-box {
                        display: grid;
                        margin-top: 16px;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-row-gap: 16px;

                        .one-msg {
                            font-size: 12px;
                            color: #00000073;

                            .one-detail {
                                color: #515561;
                            }
                        }
                    }

                    .right-content {
                        display: flex;
                        align-items: center;

                        .time-more {
                            height: 24px;
                            display: flex;

                            div {
                                border: 1px solid #d9d9d9;
                                padding: 0 8px;
                                font-size: 12px;
                                height: 100%;
                                line-height: 22px;
                                cursor: pointer;

                                &:hover {
                                    color: #048fff;
                                    background-color: #048fff1a;
                                    border-color: #048fff;
                                }
                            }

                            .active-time {
                                color: #048fff;
                                background-color: #048fff1a;
                                border-color: #048fff;
                            }
                        }
                        .time-picker {
                            margin-left: 8px;
                            cursor: pointer;

                            &:hover {
                                color: #048fff;
                            }
                            .picker {
                                margin: 0 6px;
                            }
                            .ant-calendar-picker {
                                /deep/ .ant-calendar-picker-input {
                                    width: 128px;
                                    height: 30px;
                                    font-size: 12px;
                                }
                            }
                            .rang-picker {
                                /deep/ .ant-calendar-picker-input {
                                    width: 148px;
                                }
                            }
                        }
                    }
                }

                .flex-box {
                    display: flex;
                    align-items: center;

                    div {
                        flex: 1;
                    }
                }

                .flex-end {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 10px;
                }

                #historyMonitoring {
                    height: 384px;
                    width: 100%;
                }

                .btn-operation {
                    padding: 0 14px;
                    background-color: #fff;
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    font-size: 12px;
                    height: 22px;
                    line-height: 20px;
                    color: #00000073;
                    cursor: pointer;
                    transition: all 0.5s;

                    &:hover {
                        border-color: #048fff;
                        color: #048fff;
                    }
                }
                .updown-box {
                    overflow: hidden;
                }
                .pack-up {
                    overflow: hidden;
                    height: 0;
                }
            }
        }
    }
    /*历史数据的光伏板子*/
    .pv-boxs{
	    display: flex;
	    flex-wrap: wrap;
    }
    .pv-box{
	    cursor: pointer;
	    position: relative;
	    width: 8%;
	    height: 2.5rem;
	    margin-right: 0.3125rem;
	    border-radius: 0.0625rem;
	    margin-bottom: 0.2rem;
	    background: #3780ff;
	    &::before,&::after{
		    position: absolute;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    content: "";
		    background-repeat: repeat;
		    pointer-events: none;
		    opacity: 0.5;
	    }
	    &::before {
		    background-image: linear-gradient(to right,
		    #ffffff 1px,
		    transparent 1px,
		    transparent 10px),
		    linear-gradient(to bottom, #ffffff 1px, transparent 1px, transparent 10px);
		    background-size: 20px 20px;
	    }

	    &::after {
		    background-image: linear-gradient(to right,
		    #ffffff 1px,
		    transparent 1px,
		    transparent 100px),
		    linear-gradient(to bottom,
		    #ffffff 1px,
		    transparent 1px,
		    transparent 100px);
		    background-size: 20px 20px;
	    }
    }
    .pv-numb{
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    color: #ffffff;
	    font-size: 0.35rem;
	    z-index: 10;
	    width: 100%;
	    height: 100%;
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;
    }
    .pv-box::before {
	    background-image: linear-gradient(to right, #ffffff 0.0125rem, transparent 0.0125rem, transparent 0.125rem), linear-gradient(to bottom, #ffffff 0.0125rem, transparent 0.0125rem, transparent 0.125rem);
	    background-size: 0.25rem 0.25rem;
    }
    .on {
	    background: #3780ff;
    }
    .grey {
        background: #9a9a9b;
    }
    .pv-box .close-box {
	    position: absolute;
	    top: -0.125rem;
	    right: -0.125rem;
	    z-index: 11;
	    background: #ff7875;
	    cursor: pointer;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    width: 0.23rem;
	    height: 0.23rem;
	    font-size: 0.2rem;
	    color: #fff;
	    border-radius: 50%;
    }
    .pv-popover {
	    font-size: 12px !important;
	    .title {
		    color: #53576f;
		    font-weight: 500;
		    margin-bottom: 10px;
	    }
	    .splitLine {
		    width: 100%;
		    height: 1px;
		    background: #edeef8;
		    margin: 10px 0;
	    }
    }
    /*实时发电列表按钮*/
    .raleDataList{
        padding: 0 0.175rem;
        background-color: #fff;
        border: 0.0125rem solid #b7b7b7;
        border-radius: 0.05rem;
        font-size: 0.15rem;
        height: 0.275rem;
        line-height: 0.25rem;
        color: #333;
        cursor: pointer;
        transition: all 0.5s;
        margin-right: 15px;
    }
</style>
