<template>
	<!--逆变器详情 TTL -->
	<div class="inverter-detail-box max-container">
		<div class="row-box">
			<div class="content-box">
				<div class="title-box">
					<span>基础信息</span>
					<div class="up-down" @click="changeFlag(0)">
						{{
							getInclude ( 0 ) ? "展开" : "收起"
						}}
						<a-icon type="up" :class="getInclude(0) ? 'roteIcon' : ''"/>
					</div>
				</div>
				<div :class="getInclude(0) ? 'pack-up' : 'updown-box'">
					<div class="content-msg">
						<div class="grid-box grid-real">
							<div class="one-msg">
								<span>逆变器SN：</span>
								<span class="one-detail">{{ inverterEntity.serialNumber || '--' }}</span>
							</div>
							<div class="one-msg">
								<span>相序：</span>
								<span class="one-detail">{{ inverterEntity.phaseNumber || '--' }}</span>
							</div>
							<div class="one-msg">
								<span>装机容量：</span>
								<span class="one-detail">{{ inverterEntity.vaRating || '--' }}</span>
							</div>
							<div class="one-msg">
								<span>固件序列号：</span>
								<span class="one-detail">{{ inverterEntity.firmwareVer || '--' }}</span>
							</div>
							<div class="one-msg">
								<span>型号名称：</span>
								<span class="one-detail">{{ inverterEntity.madelName || '--' }}</span>
							</div>
							<div class="one-msg">
								<span>制造商名称：</span>
								<span class="one-detail">{{ inverterEntity.manufacturer || '--' }}</span>
							</div>
							<div class="one-msg">
								<span>标称值：</span>
								<span class="one-detail">{{ inverterEntity.nomVpv || '--' }}</span>
							</div>
							<div class="one-msg">
								<span>互联网版本：</span>
								<span class="one-detail">{{ inverterEntity.interVersion || '--' }}</span>
							</div>
							<div class="one-msg">
								<span>数采SN：</span>
								<span class="one-detail">{{ inverterEntity.collectorSn || '--' }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 发电 -->
		<div class="row-box">
			<div class="content-box">
				<div class="title-box">
					<span>发电</span>
					<div class="up-down" @click="changeFlag(2)">
						{{
							getInclude ( 2 ) ? "展开" : "收起"
						}}
						<a-icon type="up" :class="getInclude(2) ? 'roteIcon' : ''"/>
					</div>
				</div>
				<div :class="getInclude(2) ? 'pack-up' : 'updown-box'">
					<div class="content-msg flex-box mt-16">
						<div class="table-box">
							<div class="table-title">
								<div>直流</div>
								<div>电压</div>
								<div>电流</div>
								<div>功率</div>
							</div>
							<div class="table-content" v-for="direct in directList">
								<div>{{ direct.title }}</div>
								<div>{{ direct.voltage }}V</div>
								<div>{{ direct.current }}A</div>
								<div>{{ direct.power }}W</div>
							</div>
						</div>
						<div class="center-box">
							<div class="progress-box">
								<div class="square-box">
									<div class="square-inner">
										<div class="square-ctn">
											<span class="small">DC/AC</span>
											<span class="line"></span>
											<span class="bolang">～</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="table-box">
							<div class="table-title">
								<div>交流</div>
								<div>电压</div>
								<div>电流</div>
								<div>频率</div>
							</div>
							<div
								class="table-content"
								v-for="communication in communicationList"
							>
								<div>{{ communication.title }}</div>
								<div>{{ communication.voltage }}V</div>
								<div>{{ communication.current }}A</div>
								<div>{{ communication.rate }}Hz</div>
							</div>
						</div>
					</div>
					<div class="power-msg">
						<div class="grid-box grid-power">
							<div class="one-msg">
								<span>直流输入总功率：</span>
								<span class="one-detail">{{ basicInfo.allDCPower || "--" }}W</span>
							</div>
							<div class="one-msg">
								<span>交流输入总功率：</span>
								<span class="one-detail">{{ basicInfo.allActivePower || "--" }}W</span>
							</div>
							<div class="one-msg">
								<span>功率因数值：</span>
								<span class="one-detail">{{ basicInfo.powerFactor || "--" }}</span>
							</div>
							<div class="one-msg">
								<span>前一日发电量：</span>
								<span class="one-detail">{{ basicInfo.yesterdayPower || "--" }}KWh</span>
							</div>
							<div class="one-msg">
								<span>累计发电量：</span>
								<span class="one-detail">{{ basicInfo.totalEnergy || "--" }}KWh</span>
							</div>
							<div class="one-msg">
								<span>上月发电量：</span>
								<span class="one-detail">{{ basicInfo.lastMonthPower || "--" }}KWh</span>
							</div>
							<div class="one-msg">
								<span>当日发电量：</span>
								<span class="one-detail">{{ basicInfo.todayEnergy || "--" }}KWh</span>
							</div>
							<div class="one-msg">
								<span>当月发电量：</span>
								<span class="one-detail">{{ basicInfo.currentMonthPower || "--" }}KWh</span>
							</div>
							<div class="one-msg">
								<span>当年发电量：</span>
								<span class="one-detail">{{ basicInfo.totalYearAmount || "--" }}KWh</span>
							</div>
							<div class="one-msg">
								<span>总共PV电压：</span>
								<span class="one-detail">{{ allPv || '--' }}V</span>
							</div>
							<div class="one-msg">
								<span>总共PV电流：</span>
								<span class="one-detail">{{ allA || '--' }}A</span>
							</div>
							<div class="one-msg">
								<span>逆变器温度：</span>
								<span class="one-detail">{{ inverterElectricEntity.inverTemp || "--" }}℃</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 历史数据 -->
		<div class="row-box">
			<div class="content-box">
				<div class="title-box">
					<span>历史数据</span>
					<div class="up-down" @click="changeFlag(8)">
						{{ getInclude ( 8 ) ? "展开" : "收起" }}
						<a-icon type="up" :class="getInclude(8) ? 'roteIcon' : ''"/>
					</div>
				</div>
				<div :class="getInclude(8) ? 'pack-up' : 'updown-box'">
					<div class="power-msg flex-end">
						<div class="right-content">
							<template v-if="templateActive != 5">
								<div class="time-more">
									<div
										v-for="t in timeTypeList"
										:class="t.value == activeTime ? 'active-time' : ''"
										@click="changeTimeType(t.value)"
									>
										{{ t.label }}
									</div>
								</div>
								<div class="btn-operation ml-16" @click="exportReport">
									导出报表
								</div>

								<div class="time-picker">
									<a-date-picker
										class="picker"
										:allowClear="false"
										v-model="historyTime"
										v-if="activeTime == 1"
									/>
									<a-week-picker
										:allowClear="false"
										v-model="historyTime"
										v-if="activeTime == 2"
									/>
									<a-month-picker
										:allowClear="false"
										v-model="historyTime"
										v-if="activeTime == 3"
									/>
									<a-date-picker
										class="picker"
										:allowClear="false"
										v-model="historyTime"
										format="YYYY"
										mode="year"
										:open="orderOpen"
										@openChange="openOrder"
										@panelChange="panelOrder"
										v-if="activeTime == 4"
									/>
								</div>
							</template>
							<div class="time-picker" v-else>
								<a-date-picker
									class="picker"
									:allowClear="false"
									v-model="rateTime"
								/>
							</div>
						</div>
					</div>
					<div class="sys-template" v-if="activeTime < 3">
						<span>系统模板：</span>
						<div
							class="btn-operation ml-16 template-style"
							:class="tem.value == templateActive ? 'choose-template' : ''"
							v-for="tem in templateList"
							@click="changeTemplate(tem.value)"
						>
							{{ tem.label }}
						</div>
					</div>

					<template v-if="templateActive != 5">
						<div id="historyE"></div>
					</template>

					<div v-else class="power-list">
						<a-empty v-if="allPowerData.length == 0"></a-empty>
						<div class="list-box" v-else>
							<div class="title-table">
								<div class="tit">时间</div>
								<div class="tit">总有功功率(KW)</div>
								<div class="tit">发电量</div>
							</div>
							<div class="all-power">
								<div class="one-power" v-for="row in allPowerData">
									<div class="one-content" v-for="column in row">
										{{ column }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	dcHistoryData, dcPowerList,
	deviceHistoryData,
	historyDownload,
	queryAcList,
	queryDcList,
	queryDeviceInverterDetail,
	queryInverterDetails, querySingleName
} from "@/api/device";
import moment from "moment";
import {exportDown} from "@/utils";
import {dcAorVEcharts, dcEcharts} from "@/views/monitoring/config/dcEcharts";
import {powerHistoryEchart} from "@/views/apply/data";
import {lineEcharts} from "@/views/monitoring/config/historyEcharts";

export default {
	props: {
		sn: {
			type: String,
			default: null
		},
		id: {
			type: String,
			default: null
		}
	},
	name: "inverterDetail",
	data() {
		return {
			inverterEntity: {},
			// 展开收起
			packUpFlag: [],
			//直流
			directList: [],
			//交流
			communicationList: [],
			basicInfo: {},
			inverterElectricEntity: {},
			allPv: 0,
			allA: 0,
			activeTime: "1",
			templateActive: "1",
			orderOpen: false,
			allPowerData: [],
			timeTypeList: [
				{
					value: "1",
					label: "日",
				}, {
					value: "2",
					label: "周",
				}, {
					value: "3",
					label: "月",
				}, {
					value: "4",
					label: "年",
				}
			],
			// 模板列表
			templateList: [
				{
					value: "1",
					label: "交流分析",
				},
				{
					value: "2",
					label: "直流电流对比",
				},
				{
					value: "3",
					label: "直流电压对比",
				},
				{
					value: "4",
					label: "直流电流和电压对比",
				},
				{
					value: "5",
					label: "实时功率",
				},
			],
			//历史数据查询时间
			historyTime: moment (),
			rateTime: moment (),
			chartInstance: null,
			allTime: [moment ( "2015" ), moment ( "2024" )],
		}
	},
	created() {
		this.querySingle ()
	},
	methods: {
		initEcharts( data ) {
			this.instance ();
			this.chartInstance.setOption ( lineEcharts ( data.xData, data.seriesData ) );
			setTimeout ( () => {
				this.chartInstance.resize ();
			}, 0 );
		},
		querySingle() {
			queryInverterDetails ( {serialNumber: this.sn} ).then ( ( res ) => {
				this.inverterEntity = res.data
				queryDcList ( {inverterSystemId: this.inverterEntity.id} ).then ( resu => {
					this.directList = resu.data
				} )
				queryAcList ( {inverterSystemId: this.inverterEntity.id} ).then ( result => {
					this.communicationList = result.data
				} )
				//通过逆变器Id查询发电详情
				queryDeviceInverterDetail ( {id: this.id} ).then ( resultPage => {
					this.basicInfo.totalEnergy = resultPage.data.baseInfo.totalEnergy
					this.basicInfo.todayEnergy = resultPage.data.baseInfo.todayEnergy
					this.basicInfo.lastMonthPower = resultPage.data.lastMonthPower
					this.basicInfo.totalYearAmount = resultPage.data.totalYearAmount
					this.basicInfo.currentMonthPower = resultPage.data.currentMonthPower
					this.basicInfo.address = resultPage.data.baseInfo.address
					this.basicInfo.power = resultPage.data.baseInfo.power
					this.basicInfo.yesterdayPower = resultPage.data.yesterdayPower
					this.inverterElectricEntity = resultPage.data.inverterElectricEntity
					this.dcList = resultPage.data.dcList
					this.acList = resultPage.data.acList
					this.allPv = this.dcList.reduce ( ( p, c ) => {
						return p + c.voltage * 1
					}, 0 )
					this.allPv = this.allPv.toFixed ( 1 )
					this.allA = this.dcList.reduce ( ( p, c ) => {
						return p + c.current * 1
					}, 0 )
					this.allA = this.allA.toFixed ( 1 )
				} )
			} )
		},
		//点击年月日按钮触发事件
		changeTimeType( value ) {
			this.activeTime = value;
			if ( this.activeTime == 3 || this.activeTime == 4 ) {
				this.templateActive = 1;
			}
			this.getHistory ();
		},
		getDetail() {
			queryDeviceInverterDetail ( {id: this.id} )
				.then ( ( res ) => {
					console.log(res.data)
					this.dealList ( res.data );
				} )
				.catch ( ( err ) => {
					console.log ( err );
				} );
		},
		dealList( newv ) {
			let totalNum = newv.dcList.reduce (
				( total, current ) => {
					return {
						totalCurrent: +total.totalCurrent + +current.current,
						totalVoltage: +total.totalVoltage + +current.voltage,
					};
				},
				{totalCurrent: 0, totalVoltage: 0}
			);
			let baseInfoData = {
				...newv.baseInfo,
				...newv.inverterElectricEntity,
				...totalNum,
				...newv,
			};
			//this.temperature[0].value = baseInfoData.airTemperature;
			this.directList = newv.dcList;
			this.communicationList = newv.acList;
			// this.baseInfo.map ( ( item ) => {
			// 	item.value = isNaN ( Number ( baseInfoData[item.prop] ) )
			// 		? baseInfoData[item.prop]
			// 		: Number ( baseInfoData[item.prop] ).toFixed ( 1 );
			// } );
			// this.powerList.map ( ( item ) => {
			// 	item.value = isNaN ( Number ( baseInfoData[item.prop] ) )
			// 		? baseInfoData[item.prop]
			// 		: Number ( baseInfoData[item.prop] ).toFixed ( 1 );
			// } );
			// this.other.map ( ( item ) => {
			// 	item.value = isNaN ( Number ( baseInfoData[item.prop] ) )
			// 		? baseInfoData[item.prop]
			// 		: Number ( baseInfoData[item.prop] ).toFixed ( 1 );
			// } );
		},
		initBarEcharts( data ) {
			this.instance ();
			this.chartInstance.setOption ( powerHistoryEchart ( data, "kWh", "发电量" ) );
			setTimeout ( () => {
				this.chartInstance.resize ();
			}, 0 );
		},
		getHistory() {
			if ( this.templateActive == 1 ) {
				deviceHistoryData ( {
					id: this.id,
					timeType: this.activeTime,
					...this.getTime (),
				} )
					.then ( ( res ) => {
						if ( this.activeTime == 2 || this.activeTime == 1 ) {
							let dealData = this.dealEchartsData ( res.data );
							this.initEcharts ( dealData );
						} else {
							let dealData = this.dealMonthYear ( res.data );
							this.initBarEcharts ( dealData );
						}
					} )
					.catch ( ( err ) => {
					} );
			} else if ( this.templateActive == 5 ) {
				this.getPowerData ();
			} else {
				dcHistoryData ( {
					id: this.id,
					timeType: this.activeTime,
					...this.getTime (),
				} ).then ( ( res ) => {
					this.dealDcData ( res.data );
				} );
			}
		},
		dealEchartsData( data ) {
			if ( Object.keys ( data ).length != 0 ) {
				let startTime = this.historyTime;
				let minTime, maxTime;
				if ( this.activeTime == 1 ) {
					minTime = moment ( startTime ).startOf ( "day" ).valueOf ();
					maxTime = moment ( startTime ).endOf ( "day" ).valueOf ();
				} else {
					minTime = moment ( startTime ).startOf ( "week" ).valueOf ();
					maxTime = moment ( startTime ).endOf ( "week" ).valueOf ();
				}
				//交流RUA
				let Rvoltage = [],
					Rcurrent = [],
					Rrate = [];
				// 交流SVB
				let Svoltage = [],
					Scurrent = [];
				// 交流TWC
				let Tvoltage = [],
					Tcurrent = [];
				let activePowerData = data.allActivePower;
				delete data.allActivePower;
				//交流总功率
				let activePower = [];
				for ( let key in data ) {
					data[key].forEach ( ( item ) => {
						if ( item.title == "交流1" ) {
							Rvoltage.push ( [moment(item.createTime).utcOffset(480).format('YYYY-MM-DD HH:mm:ss'), item.voltage] );
							Rcurrent.push ( [moment(item.createTime).utcOffset(480).format('YYYY-MM-DD HH:mm:ss'), item.current] );
							Rrate.push ( [moment(item.createTime).utcOffset(480).format('YYYY-MM-DD HH:mm:ss'), item.rate] );
						} else if ( item.title == "交流2" ) {
							Svoltage.push ( [moment(item.createTime).utcOffset(480).format('YYYY-MM-DD HH:mm:ss'), item.voltage] );
							Scurrent.push ( [moment(item.createTime).utcOffset(480).format('YYYY-MM-DD HH:mm:ss'), item.current] );
						} else if ( item.title == "交流3" ) {
							Tvoltage.push ( [moment(item.createTime).utcOffset(480).format('YYYY-MM-DD HH:mm:ss'), item.voltage] );
							Tcurrent.push ( [moment(item.createTime).utcOffset(480).format('YYYY-MM-DD HH:mm:ss'), item.current] );
						}
					} );
				}
				activePowerData.forEach ( ( item ) => {
					activePower.push ( [moment(item.createTime).utcOffset(480).format('YYYY-MM-DD HH:mm:ss'), item.gridPower] );
				} );
				Rvoltage.sort ( ( a, b ) => {
					return moment ( a[0] ).valueOf () - moment ( b[0] ).valueOf ();
				} );
				minTime = moment ( Rvoltage[0][0] ).valueOf ();
				let index = Rvoltage.length - 1;
				maxTime = moment ( Rvoltage[index][0] ).valueOf ();
				return {
					xData: {
						min: minTime,
						max: maxTime,
					},
					seriesData: [
						Rvoltage,
						// Svoltage.sort ( ( a, b ) => {
						// 	return moment ( a[0] ).valueOf () - moment ( b[0] ).valueOf ();
						// } ),
						// Tvoltage.sort ( ( a, b ) => {
						// 	return moment ( a[0] ).valueOf () - moment ( b[0] ).valueOf ();
						// } ),
						Rcurrent.sort ( ( a, b ) => {
							return moment ( a[0] ).valueOf () - moment ( b[0] ).valueOf ();
						} ),
						// Scurrent.sort ( ( a, b ) => {
						// 	return moment ( a[0] ).valueOf () - moment ( b[0] ).valueOf ();
						// } ),
						// Tcurrent.sort ( ( a, b ) => {
						// 	return moment ( a[0] ).valueOf () - moment ( b[0] ).valueOf ();
						// } ),
						Rrate.sort ( ( a, b ) => {
							return moment ( a[0] ).valueOf () - moment ( b[0] ).valueOf ();
						} ),
						activePower.sort ( ( a, b ) => {
							return moment ( a[0] ).valueOf () - moment ( b[0] ).valueOf ();
						} ),
					],
				};
			}
		},
		//点击不同模板触发
		changeTemplate( index ) {
			if ( index == this.templateActive ) {
				return;
			}
			this.templateActive = index;
			if ( this.templateActive == 5 && this.chartInstance ) {
				this.chartInstance.dispose ();
				this.chartInstance = null;
			}
			console.log('第一个模板--》' + index)
			this.$nextTick ( () => {
				this.getHistory ();
			} );
		},
		dealMonthYear( data ) {
			let xData = [],
				yData = [];
			let arrData = [];
			for ( let k in data ) {
				arrData.push ( {time: k, value: data[k]} );
			}
			arrData.sort ( ( a, b ) => {
				return moment ( a.time ).valueOf () - moment ( b.time ).valueOf ();
			} );
			arrData.map ( ( item ) => {
				xData.push ( moment ( item.time ).format ( "YYYY-MM-DD" ) );
				yData.push ( item.value );
			} );
			return {
				xData,
				yData,
			};
		},
		exportReport() {
			let startTime, endTime;
			if ( this.activeTime == 1 ) {
				startTime = moment ().startOf ( "day" ).format ( "YYYY-MM-DD 00:00:00" );
				endTime = moment ().endOf ( "day" ).format ( "YYYY-MM-DD 00:00:00" );
			} else if ( this.activeTime == 2 ) {
				startTime = moment ().startOf ( "week" ).format ( "YYYY-MM-DD 00:00:00" );
				endTime = moment ().endOf ( "week" ).format ( "YYYY-MM-DD 00:00:00" );
			} else if ( this.activeTime == 3 ) {
				startTime = moment ().startOf ( "month" ).format ( "YYYY-MM-DD 00:00:00" );
				endTime = moment ().endOf ( "month" ).format ( "YYYY-MM-DD 00:00:00" );
			} else {
				startTime = moment ().startOf ( "year" ).format ( "YYYY-MM-DD 00:00:00" );
				endTime = moment ().endOf ( "year" ).format ( "YYYY-MM-DD 00:00:00" );
			}
			let requestData = {
				id: this.id,
				timeType: this.activeTime,
				startTime,
				endTime,
			};
			historyDownload ( requestData ).then ( ( res ) => {
				exportDown ( res, "历史数据" );
				this.$message.success ( "导出数据成功" );
			} );
		},
		openOrder( val ) {
			if ( val ) {
				this.orderOpen = true;
			} else {
				this.orderOpen = false;
			}
		},
		panelOrder( val ) {
			this.historyTime = moment ( val );
			this.orderOpen = false;
		},
		getTime() {
			let startTime, endTime;
			console.log ( this.historyTime, this.timeType );
			if ( this.activeTime == 1 ) {
				startTime = moment ( this.historyTime ).format ( "YYYY-MM-DD 00:00:00" );
				endTime = moment ( this.historyTime ).format ( "YYYY-MM-DD 00:00:00" );
			} else if ( this.activeTime == 2 ) {
				startTime = moment ( this.historyTime )
					.startOf ( "week" )
					.format ( "YYYY-MM-DD 00:00:00" );
				endTime = moment ( this.historyTime )
					.endOf ( "week" )
					.format ( "YYYY-MM-DD 00:00:00" );
				console.log ( "week" );
			} else if ( this.activeTime == 3 ) {
				startTime = moment ( this.historyTime )
					.startOf ( "month" )
					.format ( "YYYY-MM-DD 00:00:00" );
				endTime = moment ( this.historyTime )
					.endOf ( "month" )
					.format ( "YYYY-MM-DD 00:00:00" );
			} else {
				startTime = moment ( this.historyTime )
					.startOf ( "year" )
					.format ( "YYYY-MM-DD 00:00:00" );
				endTime = moment ( this.historyTime )
					.endOf ( "year" )
					.format ( "YYYY-MM-DD 00:00:00" );
			}
			return {
				startTime,
				endTime,
			};
		},
		dealDcData( data ) {
			if ( Object.keys ( data ).length != 0 ) {
				let minTime, maxTime;
				if ( this.activeTime == 1 ) {
					minTime = moment ( this.historyTime ).startOf ( "day" ).valueOf ();
					maxTime = moment ( this.historyTime ).endOf ( "day" ).valueOf ();
				} else {
					minTime = moment ( this.historyTime ).startOf ( "week" ).valueOf ();
					maxTime = moment ( this.historyTime ).endOf ( "week" ).valueOf ();
				}
				let legend = [];
				let yData = [];
				let flag = 0;
				let legendText =
					this.templateActive == 2
						? "直流电流"
						: this.templateActive == 3
						? "直流电压"
						: ["直流电流", "直流电压"];
				for ( let k in data ) {
					data[k].forEach ( ( dc ) => {
						if ( flag == 0 ) {
							if ( this.templateActive == 4 ) {
								legend.push (
									...[legendText[0] + dc.title, legendText[1] + dc.title]
								);
								yData.push (
									...[
										{
											name: legendText[0] + dc.title,
											type: "line",
											data: [],
											symbol: "none",
											smooth: true,
											yAxisIndex: 0,
										},
										{
											name: legendText[1] + dc.title,
											type: "line",
											yAxisIndex: 1,
											data: [],
											smooth: true,
											symbol: "none",
										},
									]
								);
							} else {
								legend.push ( legendText + dc.title );
								yData.push ( {
									name: legendText + dc.title,
									type: "line",
									data: [],
									smooth: true,
									// areaStyle: {},
									symbol: "none",
								} );
							}
						}
						yData.forEach ( ( y ) => {
							if ( this.templateActive == 2 ) {
								if ( y.name == legendText + dc.title ) {
									y.data.push ( [dc.createTime, dc.current] );
								}
							} else if ( this.templateActive == 3 ) {
								if ( y.name == legendText + dc.title ) {
									y.data.push ( [dc.createTime, dc.voltage] );
								}
							} else {
								if ( y.name == legendText[0] + dc.title ) {
									y.data.push ( [dc.createTime, dc.voltage] );
								}
								if ( y.name == legendText[1] + dc.title ) {
									y.data.push ( [dc.createTime, dc.current] );
								}
							}
						} );
					} );
					flag++;
				}
				this.instance ();
				this.chartInstance.clear ();
				yData.forEach ( ( item ) => {
					item.data.sort ( ( a, b ) => {
						return moment ( a[0] ).valueOf () - moment ( b[0] ).valueOf ();
					} );
				} );
				minTime = moment ( yData[0].data[0][0] ).valueOf ();
				let index = yData[0].data.length - 1;
				maxTime = moment ( yData[0].data[index][0] ).valueOf ();
				if ( this.templateActive == 4 ) {
					this.chartInstance.setOption (
						dcAorVEcharts ( {min: minTime, max: maxTime}, legend, yData )
					);
				} else {
					this.chartInstance.setOption (
						dcEcharts ( {min: minTime, max: maxTime}, legend, yData )
					);
				}
				setTimeout ( () => {
					this.chartInstance.resize ();
				}, 0 );
			}
		},
		getPowerData() {
			dcPowerList ( {
				id: this.id,
				createTime: moment ( this.rateTime ).format ( "YYYY-MM-DD 00:00:00" ),
			} ).then ( ( res ) => {
				this.allPowerData = res.data.map ( ( item ) => {
					return [
						item.createTime || "--",
						item.gridPower || 0,
						item.todayEnergy || 0,
					];
				} );
			} );
		},
		instance() {
			const comparebox = document.getElementById ( "historyE" );
			if ( !this.chartInstance ) {
				this.chartInstance = this.echarts.init ( comparebox );
			}
		},
		getInclude( index ) {
			return this.packUpFlag.includes ( index );
		},
		changeFlag( index ) {
			let findIndex = this.packUpFlag.findIndex ( ( item ) => {
				return item == index;
			} );
			if ( findIndex == -1 ) {
				this.packUpFlag.push ( index );
			} else {
				this.packUpFlag.splice ( findIndex, 1 );
			}
		},
	},
	watch: {
		historyTime( newv ) {
			this.getHistory ();
			console.log ( newv );
		},
		rateTime( newv ) {
			this.getPowerData ();
		},
	},
	computed: {},
	mounted() {
		this.getDetail ();
		this.getHistory ();
	},
}
</script>

<style lang="less" scoped>

.max-container {
	.row-box {
		position: relative;
		border: 1px solid rgba(37, 43, 58, 0.1);
		border-radius: 4px;
		background: #fff;
		padding: 16px 0;
		box-sizing: border-box;
		box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
		margin-bottom: 8px;

		.content-box {
			padding: 0 16px;

			// 标题
			.title-box {
				display: flex;
				justify-content: space-between;

				span {
					font-size: 16px;
					color: #252b3a;
				}

				.up-down {
					font-size: 12px;
					color: #048fff;
					cursor: pointer;
					display: flex;
					align-items: center;

					i {
						font-size: 16px;
					}

					.roteIcon {
						transform: rotate(180deg);
					}
				}
			}

			.content-msg {
				.grid-box {
					display: grid;

					margin-top: 16px;

					.one-msg {
						font-size: 12px;
						color: #00000073;

						.one-detail {
							color: #515561;
						}
					}
				}

				.grid-base {
					grid-template-rows: 1fr 1fr;
					grid-template-columns: 1fr 1fr 1fr;
					grid-row-gap: 16px;
				}

				.grid-real {
					grid-template-columns: 1fr 1fr 1fr;
					grid-row-gap: 16px;
				}

				.grid-version {
					grid-template-columns: 1fr 1fr 1fr;
				}

				.table-box {
					width: 100%;
					display: grid;
					grid-row-gap: 16px;
					height: max-content;

					.table-title,
					.table-content {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr 1fr;
						font-size: 12px;
						color: #048fff;

						div {
							text-align: center;
						}
					}

					.table-title {
						div {
							color: #95929c;
						}
					}
				}

				.center-box {
					display: flex;
					justify-content: center;

					.progress-box {
						width: 90%;
						height: 10px;
						position: relative;
						background: #cde9ff;
						border-radius: 4px;
						@keyframes widthAdd {
							from {
								width: 0;
							}
							to {
								width: 100%;
							}
						}

						&::before {
							content: "";
							display: block;
							position: absolute;
							left: 0;
							top: 0;
							width: 0;
							animation: widthAdd 4s linear infinite;
							border-radius: 4px;
							height: 100%;
							background: #048fff;
						}

						.square-box {
							width: 56px;
							height: 56px;
							padding: 5px;
							box-sizing: border-box;
							position: absolute;
							left: 50%;
							top: 0;
							background: #fff;
							transform: translateY(-40%);

							.square-inner {
								width: 100%;
								height: 100%;
								background: #048fff;
								border-radius: 4px;
								display: flex;
								justify-content: center;
								align-items: center;
								color: #fff;

								.square-ctn {
									display: flex;
									flex-direction: column;
									font-size: 12px;
									align-items: center;

									.small {
										transform: scale(0.8);
										font-weight: 700;
									}

									.line {
										width: 70%;
										height: 1px;
										background: #fff;
									}

									.bolang {
										font-size: 20px;
										line-height: 10px;
									}
								}
							}
						}
					}
				}
			}

			.updown-box {
				overflow: hidden;
				max-height: 3000px;
			}

			.pack-up {
				overflow: hidden;
				height: 0;
			}
		}
	}
}

.content-box {
	padding: 0 16px;

	// 标题
	.title-box {
		display: flex;
		justify-content: space-between;

		span {
			font-size: 16px;
			color: #252b3a;
		}

		.up-down {
			font-size: 12px;
			color: #048fff;
			cursor: pointer;
			display: flex;
			align-items: center;

			i {
				font-size: 16px;
			}

			.roteIcon {
				transform: rotate(180deg);
			}
		}
	}

	.content-msg {
		.grid-box {
			display: grid;

			margin-top: 16px;

			.one-msg {
				font-size: 12px;
				color: #00000073;

				.one-detail {
					color: #515561;
				}
			}
		}

		.grid-base {
			grid-template-rows: 1fr 1fr;
			grid-template-columns: 1fr 1fr 1fr;
			grid-row-gap: 16px;
		}

		.grid-version {
			grid-template-columns: 1fr 1fr 1fr;
		}

		.table-box {
			width: 100%;
			display: grid;
			grid-row-gap: 16px;
			height: max-content;

			.table-title,
			.table-content {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr;
				font-size: 12px;
				color: #048fff;

				div {
					text-align: center;
				}
			}

			.table-title {
				div {
					color: #95929c;
				}
			}
		}

		.center-box {
			display: flex;
			justify-content: center;

			.progress-box {
				width: 90%;
				height: 10px;
				position: relative;
				background: #cde9ff;
				border-radius: 4px;
				@keyframes widthAdd {
					from {
						width: 0;
					}
					to {
						width: 100%;
					}
				}

				&::before {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 0;
					animation: widthAdd 4s linear infinite;
					border-radius: 4px;
					height: 100%;
					background: #048fff;
				}

				.square-box {
					width: 56px;
					height: 56px;
					padding: 5px;
					box-sizing: border-box;
					position: absolute;
					left: 50%;
					top: 0;
					background: #fff;
					transform: translateY(-40%);

					.square-inner {
						width: 100%;
						height: 100%;
						background: #048fff;
						border-radius: 4px;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #fff;

						.square-ctn {
							display: flex;
							flex-direction: column;
							font-size: 12px;
							align-items: center;

							.small {
								transform: scale(0.8);
								font-weight: 700;
							}

							.line {
								width: 70%;
								height: 1px;
								background: #fff;
							}

							.bolang {
								font-size: 20px;
								line-height: 10px;
							}
						}
					}
				}
			}
		}
	}

	.updown-box {
		overflow: hidden;
		max-height: 3000px;
	}

	.pack-up {
		overflow: hidden;
		height: 0;
	}

	//发电信息
	.btn-operation {
		padding: 0 14px;
		background-color: #fff;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		font-size: 12px;
		height: 22px;
		line-height: 20px;
		color: #00000073;
		cursor: pointer;
		transition: all 0.5s;

		&:hover {
			border-color: #048fff;
			color: #048fff;
		}
	}

	.power-msg {
		box-sizing: border-box;
		padding: 0 20px;
		margin-top: 20px;

		.grid-box {
			display: grid;
			margin-top: 16px;
			grid-template-columns: 1fr 1fr 1fr;
			grid-row-gap: 16px;

			.one-msg {
				font-size: 12px;
				color: #00000073;

				.one-detail {
					color: #515561;
				}
			}
		}

		.right-content {
			display: flex;
			align-items: center;

			.time-more {
				height: 24px;
				display: flex;

				div {
					border: 1px solid #d9d9d9;
					padding: 0 8px;
					font-size: 12px;
					height: 100%;
					line-height: 22px;
					cursor: pointer;

					&:hover {
						color: #048fff;
						background-color: #048fff1a;
						border-color: #048fff;
					}
				}

				.active-time {
					color: #048fff;
					background-color: #048fff1a;
					border-color: #048fff;
				}
			}

			.time-picker {
				margin-left: 8px;
				cursor: pointer;

				&:hover {
					color: #048fff;
				}

				.picker {
					margin: 0 6px;
				}

				.ant-calendar-picker {
					/deep/ .ant-calendar-picker-input {
						width: 128px;
						height: 30px;
						font-size: 12px;
					}

					// height: 24px;
				}

				.rang-picker {
					/deep/ .ant-calendar-picker-input {
						width: 148px;
					}
				}
			}
		}
	}

	.sys-template {
		height: 48px;
		margin-top: 8px;
		background-color: #f8f8f8;
		padding: 0 16px;
		color: #515561;
		display: flex;
		font-size: 12px;
		align-items: center;

		.how-tit {
			color: #048fff;
			cursor: pointer;
			margin-left: 24px;
		}

		.choose-template {
			color: #048fff;
			border-color: #048fff;
			background-color: #048fff1a;
		}

		.template-style {
			&:hover {
				color: #048fff;
				border-color: #048fff;
				background-color: #048fff1a;
			}
		}
	}

	#historyE {
		height: 384px;
		width: 100%;
	}

	.power-list {
		height: 450px;
		width: 100%;
		overflow-y: auto;

		.list-box {
			width: 70%;
			height: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;

			.title-table {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				height: 50px;

				.tit {
					padding: 10px 20px;
					text-align: center;
					font-size: 14px;
					color: #000;
					font-weight: 600;
				}
			}

			.all-power {
				flex-grow: 1;
				max-height: 400px;
				// over
				overflow-y: auto;
				// // overflow: hidden;
				.one-power {
					display: grid;
					overflow-y: auto;
					grid-template-columns: repeat(4, 1fr);

					.one-content {
						padding: 10px 20px;
						text-align: center;
						font-size: 12px;
						color: #515151;
						font-weight: 400;
					}
				}

				.scroll-box {
					// height: 100%;
					// overflow-y: auto;
				}
			}
		}
	}

	.flex-end {
		display: flex;
		justify-content: flex-end;
	}

	.flex-box {
		display: flex;
		align-items: center;

		div {
			flex: 1;
		}
	}
}
</style>
