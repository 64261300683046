export default [
    {
        status: '0',
        label: '在线'
    },
    {
        status: '8000',
        label: '离线'
    },
    {
        status: '1300',
        label: '按键关机'
    },
    {
        status: '1500',
        label: '紧急停机'
    },
    {
        status: '1400',
        label: '待机'
    },
    {
        status: '1200',
        label: '初始待机'
    },
    {
        status: '1600',
        label: '启动中'
    },
    {
        status: '9100',
        label: '告警运行（说明有警告信息）'
    },
    {
        status: '8100',
        label: '降额运行'
    },
    {
        status: '8200',
        label: '调度运行'
    },
    {
        status: '5500',
        label: '故障停机'
    },
]