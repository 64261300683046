<template>
    <div class="max-container">
        <div class="flex-alignc-justifyb  mb-16">
            <div></div>
            <a-button type="primary" class="add-btn" @click="openModal({})">新增组件</a-button>
        </div>
        <div class="optimize-content flex">
            <div class="flex-sub1 flex-wrap photovoltaic pd-20">
                <div class="photovoltaic-item" :class="item.voltage == 0 ? 'grey':'on'" v-for="(item,index) in list" :key="index"
                     @click.stop="openModal(item)">
                    <a-popover :overlayStyle="{ width: '200px' }" overlayClassName="photovoltaic-popover">
                        <template slot="content">
                            <div>
                                <div class="title">{{item.number}}</div>
                                <div class="flex-aligns-justifyb">
                                    <div class="flex-sub1">倾角:{{item.dipAngle}}</div>
                                    <div class="flex-sub1">方位角:{{item.azimuth}}</div>
                                </div>
                                <div class="splitLine"></div>
                                <div>
                                    <div class="flex-aligns-justifyb mb-10">
                                        <div class="flex-sub1">电压:{{item.voltage}}V</div>
                                        <div class="flex-sub1">电流:{{item.current}}A</div>
                                    </div>
                                    <div>功率:{{item.power}}W</div>
                                </div>
                            </div>
                        </template>
                        <div class="photovoltaic-num flex-center">
                            <div>{{item.power}}</div>
                            <div>w</div>
                        </div>
                    </a-popover>
                    <div class="close-box" @click.stop="handleDel(item)">
                        <a-icon type="close" class="close-icon"/>
                    </div>
                </div>

            </div>
            <div class="photovoltaic-color">
                <!-- 暂时不要 -->
                <!-- <div class="colorRuler flex-center">
                  <div>0W</div>
                  <div class="tra-fast"></div>
                  <div>0W</div>
                </div> -->
            </div>
        </div>

        <a-modal :title="form.id?'修改光伏板':'添加光伏板'" :visible="show" @ok="submit" @cancel="cancel">
            <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
                <a-form-model-item label="光伏板编号" prop="number">
                    <a-input v-model="form.number" placeholder="请输入光伏板编号"></a-input>
                </a-form-model-item>
                <a-form-model-item label="倾斜角" prop="dipAngle">
                    <a-input v-model="form.dipAngle" placeholder="请输入倾斜角"></a-input>
                </a-form-model-item>
                <a-form-model-item label="方位角" prop="azimuth">
                    <a-input v-model="form.azimuth" placeholder="请输入方位角"></a-input>
                </a-form-model-item>
                <a-form-model-item label="额定瓦数" prop="ratedWattage">
                    <a-input v-model="form.ratedWattage" placeholder="请输入额定瓦数"></a-input>
                </a-form-model-item>
                <a-form-model-item label="规格" prop="model">
                    <a-input v-model="form.model" placeholder="请输入规格"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

    </div>
</template>
<script>
    import {getOptimizerPanelList, saveOptimizerPanel, delOptimizerPanel} from "@/api/device";

    export default {
        props: {
            currentData: {
                type: Object,
                default: () => {
                },
            }
        },
        data() {
            return {
                list: [],
                show: false,
                form: {
                    number: '', // 光伏板编号
                    dipAngle: '', // 倾斜角
                    azimuth: '', // 方位角
                    ratedWattage: '', // 额定瓦数
                    model: '', // 规格
                    strandNo: '', // 所属组串号（值在上面第3步）
                    optimizerSn: '', // 优化器SN（值在上面第3步）
                },
                rules: {
                    number: [
                        {required: true, message: "请输入光伏板编号", trigger: "blur"},
                    ],
                    dipAngle: [
                        {required: true, message: "请输入倾斜角", trigger: "blur"},
                    ],
                    azimuth: [
                        {required: true, message: "请输入方位角", trigger: "blur"},
                    ],
                    ratedWattage: [
                        {required: true, message: "请输入额定瓦数", trigger: "blur"},
                    ],
                    model: [
                        {required: true, message: "请输入规格", trigger: "blur"},
                    ],
                }
            };
        },
        watch: {
            currentData: {
                handler(newVal) {
                    console.log(newVal, 'optimizerSystemId')
                    if (newVal.optimizerSystemId) {
                        this.getDetails()
                    }
                },
                immediate: true,
                deep: true
            }
        },
        mounted() {
            // this.getDetails()
        },
        methods: {
            // 获取光板列表
            getDetails() {
                getOptimizerPanelList({
                    optimizerSn: this.currentData.deviceSn, // 优化器sn
                    strandNo: this.currentData.strandNo, // 优化器详情tab
                    optimizerSystemId: this.currentData.optimizerSystemId, // 优化器详情tab id
                }).then(res => {
                    this.list = res.data
                })
            },
            openModal(item) {
                this.form = item
                this.show = true
            },
            // 关闭弹窗
            cancel() {
                this.$refs.form.resetFields();
                this.show = false;
            },
            submit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.form.optimizerSn = this.currentData.deviceSn
                        this.form.strandNo = this.currentData.strandNo
                        saveOptimizerPanel(this.form.id ? 'updateSingle' : 'saveSingle', this.form).then(res => {
                            this.$message.success({
                                content: "操作成功"
                            });
                            this.getDetails()
                            this.show = false;
                        })
                    }
                })
            },
            // 删除光板
            handleDel(item) {
                let that = this;
                this.$confirm({
                    title: "确认删除该光伏板吗?",
                    okText: "确定",
                    okType: "danger",
                    cancelText: "取消",
                    onOk() {
                        delOptimizerPanel({
                            id: item.id,
                        }).then((res) => {
                            that.getDetails()
                        });
                    },
                });
            }

        }
    };
</script>
<style lang="less">
    .photovoltaic-popover {
        font-size: 12px !important;

        .title {
            color: #53576f;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .splitLine {
            width: 100%;
            height: 1px;
            background: #edeef8;
            margin: 10px 0;
        }
    }
</style>
<style lang="less" scoped>
    .photovoltaic-color {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .colorRuler {
        cursor: pointer;

        .tra-fast {
            width: 20px;
            height: 200px;
            background: linear-gradient(360deg,
            #0e1b34 0%,
            #1b3566 25%,
            #285099 52%,
            #356bcc 76%,
            #4584ff 100%);
            border-radius: 5px;
        }
    }

    .photovoltaic {
        .photovoltaic-item {
            position: relative;
            cursor: pointer;
            position: relative;
            width: 10%;
            height: 200px;
            margin-right: 25px;
            border-radius: 5px;
            margin-bottom: 16px;

            &::before,
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                background-repeat: repeat;
                pointer-events: none;
                opacity: 0.5;
                /* background-color: red; */
            }

            &::before {
                background-image: linear-gradient(to right,
                #ffffff 1px,
                transparent 1px,
                transparent 10px),
                linear-gradient(to bottom, #ffffff 1px, transparent 1px, transparent 10px);
                background-size: 20px 20px;
            }

            &::after {
                background-image: linear-gradient(to right,
                #ffffff 1px,
                transparent 1px,
                transparent 100px),
                linear-gradient(to bottom,
                #ffffff 1px,
                transparent 1px,
                transparent 100px);
                background-size: 20px 20px;
            }

            .close-box {
                position: absolute;
                top: -10px;
                right: -10px;
                z-index: 11;
                background: #ff7875;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                font-size: 16px;
                color: #fff;
                border-radius: 50%;

                &:hover {
                    i {
                        transform: rotate(90deg);
                    }
                }

                i {
                    transition: all 0.3s;
                }
            }
        }

        .grey {
            background: #9a9a9b;
        }

        .on {
            background: #3780ff;
        }

        .photovoltaic-num {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            font-size: 28px;
            z-index: 10;
            width: 100%;
            height: 100%;
        }
    }
</style>
