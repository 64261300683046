export default ( xData, yData, seriesData ) => {
    return {
        tooltip: {
            position: 'top'
        },
        grid: {
            left: '5%',
            top: '7%',
            bottom: '20%',
            right: '2%'
        },
        xAxis: {
            type: 'category',
            data: xData,
            splitArea: {
                show: false
            }
        },
        yAxis: {
            type: 'category',
            data: yData,
            splitArea: {
                show: false
            }
        },
        visualMap: {
            min: 0,
            max: 100,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '2%',
            inRange: {
                color: ['#ff8570', 'red'],
            }
        },
        series: [
            {
                name: '发电热力图',
                type: 'heatmap',
                data: seriesData,
                label: {
                    show: true
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
}
