// 新增工单趋势
const echartConfig = (echartData, unit, legend) => {
    return {
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisTick: {
                show: false
            },
            axisLabel: {
                fontSize: 10
            }
        },
        grid: {
            left: "50px",
            right: "50px",
            bottom: "70px",
            top: "50px",
            // containLabel: true,
        },
        yAxis: {
            type: 'value',
            splitNumber: 2
        },
        legend: {
            data: ["当日新增工单"],
            bottom: "4",
            itemWidth: '10',
            itemHeight: '10',
            itemStyle: {
                color: '#048FFF'
            },
            align: 'right',
            textStyle: {
                color: '#95929c',
                fontSize: '11',
            },
            icon: 'circle',
            padding: [5, 0, 0, 0]
        },
        series: [
            {
                data: [1, 3, 4, 0, 0, 5, 9],
                type: 'line',
                symbol: 'circle',   //将小圆点改成实心 不写symbol默认空心
                symbolSize: 8,    //小圆点的大小
                itemStyle: {
                    color: '#048FFF'    //小圆点和线的颜色
                },
                name: '当日新增工单',
                smooth: true,

            }
        ]
    };
}

export let alarmDetailEcharts = (xData, legendData, yData) => {
    return {
        xAxis: {
            type: 'time',
            min: xData.min,
            max: xData.max,
            axisLabel: {
                show: true,
                showMinLabel: true,
                showMaxLabel: true
            },
        },
        grid: {
            top: '15',
            left: '50',
            right: '50',
            bottom: '40'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        legend: {
            show: true,
            data: legendData
        },
        yAxis: [
            {
                type: "value",
                alignTicks: true,
                name: 'w'
            },

        ],
        series: yData
    };
}

export const monitoringBarEcharts = (data) => {
    return {
        xAxis: {
            type: 'category',
            data: data.xData
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        yAxis: {
            type: 'value'
        },
        grid: {
            top: '15',
            left: '50',
            right: '50',
            bottom: '40'
        },
        series: [
            {
                data: data.yData,
                type: 'bar',
                smooth: true,
                barWidth: 20,
                backgroundStyle: {
                    color: "#1DA8FF",
                },
                itemStyle: {
                    color: "#048FFF",
                    borderRadius: [4, 4, 0, 0],
                },
            }
        ]
    }
}
export default echartConfig