export default {
    '1': [
        {
            title: '序号',
            dataIndex: 'index',
            width: 70,
            customRender: (text, record, index) => {
                return index + 1;
            },
            fixed: "left",
            align: 'center'
        },
        {
            title: "名称/SN",
            dataIndex: "deviceSn",
            width: 100,
            scopedSlots: {
                customRender: "deviceName",
            },
            ellipsis: true,
        },
        {
            title: "安装位置",
            width: 150,
            dataIndex: "address",
        },
        {
            title: "所属电站",
            width: 120,
            dataIndex: "stationName",
        },
        {
            title: "数据更新时间",
            width: 120,
            dataIndex: "createTime",
        },
        {
            title: "操作",
            width: 100,
            scopedSlots: {
                customRender: "operation",
            },
            fixed: 'right'
        },
    ],//采集器
    '2': [
        {
            title: '序号',
            dataIndex: 'index',
            width: 100,
            customRender: (text, record, index) => {
                return index + 1;
            },
            fixed: "left",
            align: 'center'
        },
        {
            title: "名称/id",
            width: 100,
            scopedSlots: {
                customRender: "deviceName",
            },
            ellipsis: true,
        },
        {
            title: "SN",
            dataIndex: "deviceSn",
            width: 100,
        },
        {
            title: "安装位置",
            width: 150,
            dataIndex: "address",
        },
        // {
        //     title: "设备工作状态",
        //     width: 90,
        //     dataIndex: "workStatus",
        //     scopedSlots: {
        //         customRender: "inverStatus",
        //     },
        // },
        {
            title: "所属电站",
            width: 90,
            dataIndex: "stationName",
        },
        {
            title: "发电功率(kW)",
            width: 100,
            dataIndex: "gridPower",
        },
        {
            title: "当日发电量(kWh)",
            width: 100,
            dataIndex: "todayEnergy",
        },
        {
            title: "当日满发小时(h)",
            width: 100,
            dataIndex: "fullHour",
        },
        {
            title: "功率归一化（%）",
            width: 120,
            dataIndex: "avgActivePower",
        },
        {
            title: "装机容量(KWh)",
            width: 120,
            dataIndex: "power",
        },
        {
            title: "所属父设备",
            width: 150,
            dataIndex: "parentName",
        },
        {
            title: "数据更新时间",
            width: 120,
            dataIndex: "createTime",
        },
        {
            title: "操作",
            width: 90,
            scopedSlots: {
                customRender: "operation",
            },
            fixed: 'right'
        },
    ]
}


