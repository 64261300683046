export const lineEcharts = (xData, seriesData) => {
    return {
        xAxis: {
            type: 'time',
            min: xData.min,
            max: xData.max,
            axisLabel: {
                show: true,
                showMinLabel: true,
                showMaxLabel: true
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        grid: {
            top: '50',
            left: '80',
            bottom: '30',
            right: '80'
        },
        legend: {
            data: ['交流电压RUA', '交流电流RUA', '交流输出频率R', '交流输出总功率(有功)'],
        },
        // yAxis: {
        //     type: 'value',
        //     name: 'Hz, V, A, kW',
        // },
        yAxis: [
            {
                name: "HZ",
                type: "value",
                position: "left",
                offset: 35,
                alignTicks: true,

                // axisLabel: {
                //     formatter: "{value} 元"
                // }
            },
            {
                name: "V",
                type: "value",
                position: "left",

                alignTicks: true
                // axisLabel: {
                //     formatter: "{value} 元"
                // }
            },
            {
                name: "A",
                type: "value",
                position: "right",

                alignTicks: true,

                // axisLabel: {
                //     formatter: "{value} 元"
                // }
            },
            {
                name: "W",
                type: "value",
                position: "right",

                alignTicks: true,
                offset: 40
                // axisLabel: {
                //     formatter: "{value} 元"
                // }
            },
        ],
        series: [
            {
                name: '交流电压RUA',
                type: 'line',
                data: seriesData[0],
                yAxisIndex: 1,
                smooth: true,
                // areaStyle: {},
                symbol: 'none'
            },
            {
                name: '交流电流RUA',
                type: 'line',
                data: seriesData[1],
                yAxisIndex: 2,
                smooth: true,
                // areaStyle: {},
                symbol: 'none'
            },
            {
                name: '交流输出频率R',
                type: 'line',
                data: seriesData[2],
                yAxisIndex: 0,
                smooth: true,
                // areaStyle: {},
                symbol: 'none'
            },
            {
                name: '交流输出总功率(有功)',
                type: 'line',
                data: seriesData[3],
                yAxisIndex: 3,
                smooth: true,
                // areaStyle: {},
                symbol: 'none'
            },
        ]
    }
}


export const BarEcharts = (xData) => {
    return {
        xAxis: {
            type: 'category',
            data: xData
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: 'bar',
                smooth: true
            }
        ]
    }
}

export const monitoringBarEcharts = (data) => {
    return {
        xAxis: {
            type: 'category',
            data: data.xData
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: data.yData,
                type: 'bar',
                smooth: true
            }
        ]
    }
}
