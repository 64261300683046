export const dcEcharts = (xData, legendData, yData) => {
    return {
        xAxis: {
            type: 'time',
            // interval: 24 * 60 * 60 * 1000,
            min: xData.min,
            max: xData.max,
            axisLabel: {
                show: true,
                showMinLabel: true,
                showMaxLabel: true
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        grid: {
            top: '50',
            left: '60',
            bottom: '30',
            right: '60'
        },
        legend: {
            data: legendData
        },
        // yAxis: {
        //     type: 'value',
        //     name: 'Hz, V, A, kW',
        // },
        yAxis: [
            {
                name: "A",
                type: "value",
                // axisLabel: {
                //     formatter: "{value} 元"
                // }
            },

        ],
        series: yData
    }
}

export const dcAorVEcharts = (xData, legendData, yData) => {
    return {
        xAxis: {
            type: 'time',
            min: xData.min,
            max: xData.max,
            axisLabel: {
                show: true,
                showMinLabel: true,
                showMaxLabel: true
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        grid: {
            top: '50',
            left: '60',
            bottom: '30',
            right: '60'
        },
        legend: {
            data: legendData
        },
        // yAxis: {
        //     type: 'value',
        //     name: 'Hz, V, A, kW',
        // },
        yAxis: [
            {
                name: "A",
                type: "value",
                position: "right",
                alignTicks: true
                // axisLabel: {
                //     formatter: "{value} 元"
                // }
            },
            {
                name: "v",
                type: "value",
                position: "left",
                alignTicks: true
                // axisLabel: {
                //     formatter: "{value} 元"
                // }
            },

        ],
        series: yData
    }
}